import styled from 'styled-components'

export const Hr = styled.hr`
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
  height: 0.5px;
  width: 90%;
  margin: 0.6rem 0 1.8rem 0;
  display: ${({showForm, validationDocument}) =>{if(showForm) return 'flex'; return validationDocument ? 'none': 'flex'}};
`


export const DataList = styled.ul`
  width: 94%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* margin: 1.5rem 0rem 0; */
  margin: 0 0 3rem 0;

  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background: linear-gradient(
      0deg,
      rgba(244, 244, 244, 0.5) 0%,
      rgba(255, 250, 250, 0.5) 100%
    );
    background-color: #ccc;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ validationDocument, validated }) => {
      if (!validationDocument) {
        return '#a6151c'
      }
      return validated ? '#9ca624' : '#a6151c'
    }};
    border-radius: 3px;
  }

  @media (max-width: 1022px) {
    margin: 0 0 3rem 0;
  }
`

export const DataListItem = styled.li`
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 0;
  border-bottom: 0.5px solid rgb(221, 221, 221);
  font-size: 0.8rem;
  position: relative;
  font-family: 'Museo500sans';
  p {
    color: rgb(143 143 143);
    &::first-letter {
      text-transform: uppercase;
    }
    strong {
      font-weight: bold;
      font-size: 0.9rem;
      font-family: Museo500sans;
      color: rgb(55, 55, 55);
      padding: 0 0.4rem 0 0;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &:last-child {
    border-bottom: none;
  }
`

export const StrongP = styled.strong`
  background-color: red;
`

export const BtnCopy = styled.button`
  background-color: #6f6f6f;
  border: none;
  border-radius: 5px;
  padding: 0 0.4rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.05s ease-in;
  &:active {
    transform: scale(0.98);
  }
  &:hover {
    background-color: rgb(87 86 86);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`

export const ConfirmCopyValue = styled.div`
  display: ${({ openCopyButton }) => (openCopyButton ? 'flex' : 'none')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  font-size: 0.9rem;
  position: absolute;
  right: 2.5rem;
  z-index: 9;
  background: rgba(0, 0, 0, 0.7);
  color: rgb(255, 255, 255);
  padding: 0 0.6rem 0 0.4rem;
  height: 2rem;
  width: 5.8rem;
  border-radius: 6px;
  align-items: center;
  font-family: Museo500sans;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
  transition: opacity 0.4s ease-in-out;
  justify-content: center;
  img {
    width: 1rem;
    margin: 0 0.4rem 0 0rem;
  }

  svg {
    font-size: 0.9rem;
  }
`

export const DataTitle = styled.div`
  width: 90%;
  margin: ${({ validationDocument }) =>
    validationDocument ? '0 0 1rem' : '0rem 0 1rem'};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  h2 {
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: #373737;
    line-height: 22px;
  }

  hr {
    height: 3.5px;
    /* background-color: ${({ docValidated }) =>
      docValidated ? '#9ca624' : '#a6151c'}; */
    background-color: ${({ validationDocument, docValidated }) => {
      return !validationDocument
        ? '#a6151c'
        : docValidated
        ? '#9ca624'
        : '#a6151c'
    }};
    border: none;
    border-radius: 2px;
    width: 2rem;
    transition: background-color 0.2s ease-out;
  }

  @media (max-width: 500px) {
    display: flex;
    justify-content: flex-end;
  }
`

export const ContentNoInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0 3rem;
`

export const Content = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  p {
    color: #373737;
    font-size: 0.9rem;
    font-weight: bold;
    font-family: 'Museo500sans';
    letter-spacing: 0.5px;
    color: #373737;
    position: relative;
    span {
      width: 1.2rem;
    }
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 0.3rem;
    position: relative;
    top: 4px;
  }
  svg path {
    fill: #a6151c;
  }
`

export const BtnOpenDataDocs = styled.li`
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 0;
  border-bottom: 0.5px solid rgb(221, 221, 221);
  font-size: 0.9rem;
  position: relative;
  font-family: 'Museo500sans';
  color: #a6151c;
  p {
    color: rgb(143 143 143);
    &::first-letter {
      text-transform: uppercase;
    }
    
  }
  strong {
      font-weight: bold;
      font-size: 0.9rem;
      font-family: Museo500sans;
      color: rgb(55, 55, 55);
      padding: 0 0.4rem 0 0;
      &::first-letter {
        text-transform: uppercase;
      }
    }

  &:last-child {
    border-bottom: none;
  }
  transition: background 0.3s ease-in;
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`

export const SeeMore = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0.2rem 0rem 0.2rem 0;
  svg {
    width: 1.1rem;
    height: 1.1rem;
    margin: 0 0.4rem 0 0;
  }
`
