export default function insertSpaceBetweenWords(input) {
  return input
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
}

export const checkValidation = (
  handleSelectedDocument,
  idFileDoc,
  analyzerDataList,
  handleQttValidation,
  setDocValidate
) => {
  const selectedItem = analyzerDataList?.find(
    (item) => item.retorno.idDocBox === idFileDoc
  )

  handleSelectedDocument(selectedItem)

  if (selectedItem?.retorno?.validado === true) {
    handleQttValidation(true)
    setDocValidate(true)
  } else {
    handleQttValidation(false)
    setDocValidate(false)
  }
}

export const groupDocuments = (documents, tagsWithColors) => {
  if (!documents) return []

  const groupedDocuments = documents
    .filter((doc) => doc.part)
    .reduce((acc, doc) => {
      const numberTag = doc.numberTag ?? 1

      const key = `${doc.part}-${numberTag}`
      const getColorForTag = tagsWithColors.find(
        (t) => t.part === doc.part && t.numberTag === numberTag
      )
      const hasExtraTag = tagsWithColors.find(
        (t) => t.part === doc.part && t.numberTag === numberTag
      )

      if (!acc[key]) {
        acc[key] = {
          part: doc.part,
          numberTag: numberTag,
          dataValidated: doc.dataValidated,
          documentId: [doc?.documentId],
          color: getColorForTag?.color,
          tagAdd: hasExtraTag?.tagAdd,
        }
      } else {
        acc[key].documentId.push(doc.documentId)
      }

      return acc
    }, {})

  const validateds = documents
    .filter((item) => item.validated === true)
    .reduce(
      (acc, item) => {
        acc.documentId.push(item.documentId)
        return acc
      },
      {
        part: 'Validados',
        numberTag: null,
        color: '#9ca624',
        documentId: [],
        tagAdd: '',
      }
    )

  const notHasTags = documents
    .filter((item) => item.part === '' || item.part === undefined || item.part === null)
    .reduce(
      (acc, item) => {
        acc.documentId.push(item.documentId)
        return acc
      },
      {
        part: 'Não Cadastrados',
        numberTag: null,
        color: '#B0B3B7',
        documentId: [],
        tagAdd: '',
      }
    )

  const notValidateds = documents
    .filter(
      (item) =>
        item.validated === false ||
        item.validated === null ||
        item.validated === '' ||
        item.validated === undefined
    )
    .reduce(
      (acc, item) => {
        acc.documentId.push(item.documentId)
        return acc
      },
      {
        part: 'Pendentes',
        numberTag: null,
        color: '#a6151c',
        documentId: [],
        tagAdd: '',
      }
    )

  return [...Object.values(groupedDocuments),notHasTags, validateds, notValidateds]
}
