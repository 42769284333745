import React, { useContext, useEffect, useState } from 'react'
import * as S from './styles'
import { DocsContext } from '../../context/docs-context'
import ModalListDocuments from '../modal-list-documents'

const ProgressBar = () => {
  const [totalValidateds, setTotalValidateds] = useState(0)
  const [progressWidth, setProgressWidth] = useState(0)
  let { documentListData } = useContext(DocsContext)
  const {
    showForm,
    totalValidateDocs,
    setAllDocsValidated,
    allDocsValidated,
    validationDocument,
    progressBar,
    configData,
    partFormListState,
    handleOpenModalValidatedDocuments,
  } = useContext(DocsContext)

  useEffect(() => {
    if (showForm) {
      // console.log('cade seu deus agora?')
      setTotalValidateds(
        partFormListState.filter((item) => item.dataValidated === true).length
      )
      setProgressWidth(
        (totalValidateds /
          (partFormListState.length === undefined
            ? (partFormListState = 0)
            : partFormListState.length)) *
          100
      )
      // console.log('totalValidatedsssssssssssssssss', totalValidateds)
    } else {
      setTotalValidateds(
        configData?.documents?.filter((item) => item.validated).length
      )
      setProgressWidth(
        (totalValidateds /
          (documentListData.length === undefined
            ? (documentListData = 0)
            : documentListData.length)) *
          100
      )
      // console.log('totalValidatedsssssssssssssssss', totalValidateds)
    }
  })

  useEffect(() => {
    progressWidth === 100
      ? setAllDocsValidated(true)
      : setAllDocsValidated(false)
  }, [progressWidth, setAllDocsValidated])

  return (
    <S.Section
      showForm={showForm}
      validationDocument={validationDocument}
      showProgrees={progressBar}
    >
      <S.Container>
        <ModalListDocuments />

        <S.DocsCountValidated allDocsValidated={allDocsValidated}>
          <S.SectionProgressBar
            allDocsValidated={allDocsValidated}
            validationDocument={validationDocument}
            showProgrees={progressBar}
          >
            <S.ProgressBar allDocsValidated={allDocsValidated}>
              <S.ProgressFill
                progressWidth={progressWidth}
                allDocsValidated={allDocsValidated}
              />
            </S.ProgressBar>
            <S.ProgressBarData>
              <S.ContentProgressBarData>
                <p>{showForm ? 'Dados' : 'Documentos'} validados</p>
                <p>
                  {totalValidateds}/
                  {showForm
                    ? partFormListState.length
                    : documentListData.length}
                </p>
              </S.ContentProgressBarData>
            </S.ProgressBarData>
          </S.SectionProgressBar>
          <S.ContentButton>
            <S.Button
              allDocsValidated={allDocsValidated}
              onClick={handleOpenModalValidatedDocuments}
            >
              ENVIAR
            </S.Button>
            {/* <S.ContentMinWidth>
              <FilterByContractingParts
                validatedDocument={validatedDocument}
                tagsWithColors={tagsWithColors}
              />
            </S.ContentMinWidth> */}
          </S.ContentButton>
        </S.DocsCountValidated>
        {/* <S.ContentMaxWidth>
          <FilterByContractingParts
            validatedDocument={validatedDocument}
            tagsWithColors={tagsWithColors}
          />
        </S.ContentMaxWidth> */}
      </S.Container>
    </S.Section>
  )
}

export default ProgressBar
