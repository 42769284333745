import styled, { keyframes } from 'styled-components'

export const Form = styled.form`
  width: 100%;
  margin: 1.5rem 0 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
export const Input = styled.input`
  width: 100%;
  height: 2.2rem;
  border-radius: 8px;
  border: none;
  background-color: #f4f4f4;
  padding: 0 1rem;
  font-family: 'Museo300Sans';
  letter-spacing: 0.2px;
`

export const Label = styled.label`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 0.75rem;
  margin: 0 0 0.5rem 0;
  padding: 0 0 0 0.5rem;
  color: #464646;
`

export const WrapperInput = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  &:first-child {
    margin: 0 0 0.5rem;
  }
`

const blinkScreen = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
`

export const LoadingList = styled.ul`
  margin: 0.5rem 0;
`

export const LoadingListItem = styled.li`
  width: 100%;
  flex-direction: column;
  height: 4rem;
  border-bottom: 0.5px solid rgb(221, 221, 221);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0.5rem 0;
  animation:  ${blinkScreen} 2s ease-in-out infinite;
  p {
    border-radius: 8px;
    width: 92%;
    height: 2.5rem;
    background-color: #e8e8e8;
    margin: 0 0.5rem 0 0.5rem;
  }
  label {
    width: 5rem;
    border-radius: 4px;
    height: 1rem;
    margin: 0px 0px 0.2rem 1rem;
    background-color: #d3d3d3;
  }
`
