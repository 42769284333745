export const documentstype = [
  { id: 1, name: 'Passaporte' },
  { id: 2, name: 'Identidade' },
  { id: 3, name: 'Fatura' },
  { id: 4, name: 'Contrato Social' },
  { id: 5, name: 'Conta de Luz' },
  { id: 6, name: 'CPF' },
  { id: 7, name: 'Titulo de Eleitor' },
  { id: 8, name: 'Anexo' },
]

export const contractParties = [
  { id: 1, name: 'Locatário 1' },
  { id: 2, name: 'Fiador 1' },
  { id: 3, name: 'Empresa' },
  { id: 4, name: 'Sócio locatario 1' },
  { id: 5, name: 'Fiador locatario 1' },
  { id: 6, name: 'Outros' },
]

export const loadingDocSummary = [
 '', '', '', '', '', '', '', ''
]

export const contractOptions = [
  { label: 'Nome Completo', key: 'fullName', type: 'text' },
  { label: 'Endereço', key: 'address', type: 'text' },
  { label: 'Cidade', key: 'city', type: 'text' },
  { label: 'Estado', key: 'state', type: 'text' },
  { label: 'CEP', key: 'postalCode', type: 'text' },
  { label: 'Telefone', key: 'phone', type: 'text' },
  { label: 'E-mail', key: 'email', type: 'email' },
  { label: 'CPF', key: 'cpf', type: 'text' },
  { label: 'Data de Nascimento', key: 'birthDate', type: 'date' },
  // { label: 'Data de Início do Contrato', key: 'contractStartDate', type: 'date' },
  // { label: 'Data de Término do Contrato', key: 'contractEndDate', type: 'date' },
  // { label: 'Valor do Contrato', key: 'contractValue', type: 'currency' },
  // { label: 'Descrição do Serviço', key: 'serviceDescription', type: 'textarea' },
  // { label: 'Assinatura', key: 'signature', type: 'text' },
];

export const defaultColors = [
  { id: 0, color: 'rgb(13, 71, 161)' },
  { id: 1, color: 'rgb(21, 209, 34)' },
  { id: 2, color: 'rgb(255, 87, 34)' },
  { id: 3, color: 'rgb(91, 14, 93)' },
  { id: 4, color: 'rgb(1, 37, 3)' },
  { id: 5, color: 'rgb(255, 0, 0)' },
  { id: 6, color: 'rgb(0, 150, 136)' },
  { id: 7, color: 'rgb(121, 85, 72)' },
  { id: 8, color: 'rgb(63, 81, 181)' },
  { id: 9, color: 'rgb(233, 17, 114)' },
  { id: 10, color: 'rgb(255, 193, 7)' },
  { id: 11, color: 'rgb(156, 39, 176)' },
  { id: 12, color: 'rgb(0, 188, 212)' },
  { id: 13, color: 'rgb(139, 195, 74)' },
  { id: 14, color: 'rgb(233, 30, 99)' },
  { id: 15, color: 'rgb(103, 58, 183)' },
  { id: 16, color: 'rgb(244, 67, 54)' },
  { id: 17, color: 'rgb(76, 175, 80)' },
  { id: 18, color: 'rgb(33, 150, 243)' },
  { id: 19, color: 'rgb(255, 152, 0)' },
  { id: 20, color: 'rgb(96, 125, 139)' },
  { id: 21, color: 'rgb(0, 123, 255)' },
  { id: 22, color: 'rgb(46, 125, 50)' },
  { id: 23, color: 'rgb(255, 87, 127)' },
  { id: 24, color: 'rgb(0, 123, 167)' },
  { id: 25, color: 'rgb(255, 109, 0)' },
  { id: 26, color: 'rgb(103, 109, 114)' },
  { id: 27, color: 'rgb(74, 20, 140)' },
  { id: 28, color: 'rgb(230, 74, 25)' },
  { id: 29, color: 'rgb(67, 160, 71)' },
  { id: 30, color: 'rgb(175, 34, 30)' },
  { id: 31, color: 'rgb(2, 136, 209)' },
  { id: 32, color: 'rgb(255, 234, 0)' },
  { id: 33, color: 'rgb(109, 76, 65)' },
  { id: 34, color: 'rgb(3, 169, 244)' },
  { id: 35, color: 'rgb(255, 143, 0)' },
  { id: 36, color: 'rgb(216, 27, 96)' },
  { id: 37, color: 'rgb(0, 77, 64)' },
  { id: 38, color: 'rgb(69, 32, 179)' },
  { id: 39, color: 'rgb(110, 7, 8)' },
  { id: 40, color: 'rgb(245, 127, 23)' },
  { id: 41, color: 'rgb(44, 216, 169)' },
  { id: 42, color: 'rgb(65, 47, 127)' },
  { id: 43, color: 'rgb(193, 100, 92)' },
  { id: 44, color: 'rgb(238, 70, 179)' },
  { id: 45, color: 'rgb(23, 205, 30)' },
  { id: 46, color: 'rgb(82, 94, 244)' },
  { id: 47, color: 'rgb(126, 231, 37)' },
  { id: 48, color: 'rgb(4, 96, 71)' },
  { id: 49, color: 'rgb(56, 32, 96)' },
  { id: 50, color: 'rgb(31, 50, 38)' },
  { id: 51, color: 'rgb(78, 119, 225)' },
  { id: 52, color: 'rgb(5, 113, 20)' },
  { id: 53, color: 'rgb(67, 33, 142)' },
  { id: 54, color: 'rgb(109, 50, 132)' },
  { id: 55, color: 'rgb(111, 29, 24)' },
  { id: 56, color: 'rgb(52, 40, 18)' },
  { id: 57, color: 'rgb(155, 67, 39)' },
]
