import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { DocsProvider } from './context/docs-context'
import { EventType } from '@azure/msal-browser'
import { useMsal, useAccount } from '@azure/msal-react'
import FetchInterceptor from './interceptor/fetchInterceptor'
import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer } from 'react-toastify'
import LoaderInitial from './components/loader-initial'
import Routes from './routes/routes'
import Header from './components/header/Header'
import GlobalStyle from './GlobalStyle'
import './App.css'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { getAppInsights, reactPlugin } from './insights/app-insights'

const App = () => {
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0] || {})

  const appInsights = getAppInsights()
  // useEffect(() => {
  //   appInsights &&
  //     appInsights.trackEvent({
  //       name: 'Teste',
  //       properties: { teste: 'teste1' },
  //     })
  //   console.log({ appInsights })
  // }, [])

  const location = useLocation()

  useEffect(() => {
    appInsights.trackPageView({ name: location.pathname })
  }, [location])

  useEffect(() => {
    if (instance) {
      if (account) {
        const { username } = account
        appInsights.setAuthenticatedUserContext(username)
        instance.setActiveAccount(account)
      } else {
        appInsights.clearAuthenticatedUserContext()
      }

      instance.addEventCallback((event) => {
        if (
          event.eventType === EventType.LOGIN_SUCCESS &&
          event.payload.account
        ) {
          const account = event.payload.account
          instance.setActiveAccount(account)
        }
      })

      instance
        .handleRedirectPromise()
        .then((authResult) => {
          const account = instance.getActiveAccount()
          if (!account) {
            instance.loginRedirect()
          } else {
            if (authResult) {
              localStorage.setItem('token', authResult?.idToken)
              localStorage.setItem(
                'tokenExp',
                authResult?.account?.idTokenClaims?.exp.toString()
              )
            }
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [account, instance])

  if (accounts.length > 0) {
    return (
      <>
        <GlobalStyle />
        <FetchInterceptor>
          <DocsProvider>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <Header />
            <Routes />
          </DocsProvider>
        </FetchInterceptor>
      </>
    )
  } else {
    return (
      <>
        <GlobalStyle />
        <LoaderInitial />
      </>
    )
  }
}

export default withAITracking(reactPlugin, App)
