import styled, { keyframes } from 'styled-components'

const drawLine = keyframes`
  from {
    transform: scale(0) rotate(55deg);
  }
  to {
    transform: scale(1) rotate(55deg);
  } 
`

const drawLine2 = keyframes`
  from {
    transform: scale(0) rotate(-55deg);
  }
  to {
    transform: scale(1) rotate(-55deg);
  } 
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Line1 = styled.div`
  width: 10px;
  height: 3.5px;
  border-radius: 50px;
  background-color: #9ca624;
  transform-origin: left;
  transform: rotate(55deg) scale(0);
  animation: ${drawLine} 0.1s ease-in-out forwards;
  animation-delay: 0s;
  position: relative;
  left: 0px;
  bottom: 0px;
  right: 0px;
  top: 7px;
`

const Line2 = styled.div`
  border-radius: 50px;
  background-color: #9ca624;
  transform-origin: bottom left;
  transform: rotate(-55deg) scale(0);
  animation: ${drawLine2} 0.1s ease-in-out forwards;
  animation-delay: 0.5s;
  position: relative;
  width: 24px;
  height: 3.5px;
  top: 16px;
  bottom: 0px;
  right: 4px;
`

const ValidateDocument = () => {
  return (
    <Container>
      <>
        <Line1 />
        <Line2 />
      </>
    </Container>
  )
}

export default ValidateDocument
