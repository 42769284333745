import React, { useContext } from 'react'
import * as S from './styles'
import Logo from '../../assets/image/logo_multiplan_header.svg'
import { ReactComponent as Back } from '../../assets/image/back-to-main.svg'
import { DocsContext } from '../../context/docs-context'

const Header = () => {
  const { openDocumentPreview, handlePreviewDocsReview } =
    useContext(DocsContext)

  return (
    <S.Header openDocumentPreview={openDocumentPreview}>
      <S.Logo className="logo">
        <img src={Logo} alt="logo" />
      </S.Logo>
      {openDocumentPreview ? (
        <S.BtnBack
          title={'Voltar a página inicial'}
          onClick={handlePreviewDocsReview}
        >
          <Back />
        </S.BtnBack>
      ) : null}
    </S.Header>
  )
}

export default Header
