import React, { useContext, useEffect, useState } from 'react'
import * as S from './styles'
import DocumentNotValidate from '../document-not-validate/DocumentNotValidate'
import ValidatedDocument from '../validated-document/ValidatedDocument'
import { ReactComponent as CheckList } from '../../assets/image/checklist.svg'
import FilterByContractingParts from '../filter-by-contracting-parts/FilterByContractingParts'
import { DocsContext } from '../../context/docs-context'

const DocumentValidation = () => {
  const {
    idFileDoc,
    activeTag,
    configData,
    validationDocument,
    showForm,
    handleOpenModalValidatedDocuments,
  } = useContext(DocsContext)
  const [validatedDocument, setValidatedDocument] = useState({})

  useEffect(() => {
    const documentToValidated = configData?.documents?.find(
      (item) => item?.documentId === idFileDoc
    )
    setValidatedDocument(documentToValidated)
  }, [idFileDoc, configData?.documents])

  return (
    <S.Section>
      <S.Validation validatedDocument={validatedDocument?.validated}>
        <S.Content
          showForm={showForm}
          validationDocument={validationDocument}
          dataValidaded={activeTag?.dataValidated}
          validatedDocument={validatedDocument?.validated}
        >
          <S.Title>
            <div>
              <h1>DOCUMENTO</h1>
              {!showForm ? (
                validationDocument ? (
                  validatedDocument?.validated ? (
                    <h1>VALIDADO</h1>
                  ) : (
                    <h1>NÃO VALIDADO</h1>
                  )
                ) : null
              ) : null}
            </div>
            <hr />
          </S.Title>
          {/* {validationDocument ? (
            <S.ConfirmDocumentValidate
              validationDocument={validationDocument}
              validated={validatedDocument?.validated}
            >
              <S.CircleBox></S.CircleBox>
              <span>
                {validatedDocument?.validated ? (
                  <ValidatedDocument />
                ) : (
                  <DocumentNotValidate />
                )}
              </span>
            </S.ConfirmDocumentValidate>
          ) : null} */}
          {!showForm && validationDocument ? (
            <S.ConfirmDocumentValidate
              validationDocument={validationDocument}
              validated={validatedDocument?.validated}
            >
              <S.CircleBox></S.CircleBox>
              <span>
                {validatedDocument?.validated ? (
                  <ValidatedDocument />
                ) : (
                  <DocumentNotValidate />
                )}
              </span>
            </S.ConfirmDocumentValidate>
          ) : null}
        </S.Content>
        {/* <FilterByContractingParts /> */}
        {!showForm && <FilterByContractingParts />}
        {showForm && (
          <S.BtnCheckList
            onClick={handleOpenModalValidatedDocuments}
            title={'Resumir Documentos'}
          >
            <CheckList />
          </S.BtnCheckList>
        )}
      </S.Validation>
    </S.Section>
  )
}

export default DocumentValidation
