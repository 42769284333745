import React, { useContext, useRef, useEffect } from 'react'
import * as S from './styles'
import { DocsContext } from '../../context/docs-context'
import Ok from '../../assets/image/check-mark-png.png'
import { ReactComponent as Confirm2 } from '../../assets/image/confirm-9.svg'
import { ReactComponent as ComboBox } from '../../assets/image/dropdown6.svg'
import { ReactComponent as Close } from '../../assets/image/close-modal.svg'

const ModalListDocuments = () => {
  const {
    documentsPartArray,
    configData,
    analyzerDataList,
    handleOpenModalValidatedDocuments,
    validatedDocumentsModalOpen,
    validationDocument,
    setValidatedDocumentsModalOpen,
    configId,
    showForm,
    showListDocuments,
  } = useContext(DocsContext)

  useEffect(() => {
    if (validatedDocumentsModalOpen) {
      window?.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [validatedDocumentsModalOpen])

  const modalContainerRef = useRef(null)

  const groupedDocuments = documentsPartArray?.reduce((acc, doc) => {
    const key = `${doc?.part}-${doc?.numberTag}`

    if (!acc[key]) {
      acc[key] = {
        id: Object.keys(acc).length + 1,
        part: doc?.part,
        numberTag: doc?.numberTag,
        documents: [],
        tagAdd: doc?.tagAdd,
      }
    }

    doc?.documentId?.forEach((id) => {
      const docConfigData = configData?.documents?.find(
        (item) => item?.documentId === id
      )
      const docAnalyzerData = analyzerDataList?.find(
        (item) => item?.retorno && item?.retorno?.idDocBox === id
      )

      if (docConfigData || docAnalyzerData) {
        acc[key].documents.push({
          documentId: id,
          documentData: {
            configData: docConfigData || {},
            analyzerData: docAnalyzerData?.retorno || {},
          },
        })
      }
    })

    return acc
  }, {})

  const otherDocuments = configData?.documents?.reduce((acc, doc) => {
    if (!doc?.part && !doc?.numberTag) {
      const docAnalyzerData = analyzerDataList?.find(
        (item) => item?.retorno && item?.retorno?.idDocBox === doc?.documentId
      )

      acc.push({
        documentId: doc?.documentId,
        documentData: {
          configData: doc || {},
          analyzerData: docAnalyzerData?.retorno || {},
        },
      })
    }
    return acc
  }, [])

  if (otherDocuments?.length > 0) {
    groupedDocuments['Outros'] = {
      id: Object?.keys(groupedDocuments).length + 1,
      part: 'Outros',
      numberTag: null,
      documents: otherDocuments,
    }
  }

  const finalArray = Object?.values(groupedDocuments)

  const handleClose = () => {
    document.cookie = `analyzer_view_${configId}=close;domain=.multiplan.com.br`
  }

  const handleClickOutside = (event) => {
    if (
      modalContainerRef?.current &&
      !modalContainerRef?.current.contains(event?.target)
    ) {
      setValidatedDocumentsModalOpen(false)
    } else {
      setValidatedDocumentsModalOpen(true)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [])

  return (
    <S.Section validatedDocumentsModalOpen={validatedDocumentsModalOpen}>
      <S.Container ref={modalContainerRef}>
        <S.ContentBox></S.ContentBox>
        <S.Content>
          <h2>DOCUMENTOS CLASSIFICADOS</h2>
          <hr />
          <S.DocumentsList validationDocument={validationDocument}>
            {finalArray
              .filter(
                (item) =>
                  item?.part !== 'Validados' &&
                  item?.part !== 'Pendentes' &&
                  item?.part !== 'Não Cadastrados'
              )
              .sort((a, b) => a.part?.localeCompare(b.part))
              .map((item) => (
                <S.DocumentsListItem
                  key={item.id}
                >
                  <h3>
                    <div></div>
                    {item?.part}
                    {item?.part !== 'Outros' &&
                    item?.tagAdd !== 'extraTag' &&
                    item?.numberTag
                      ? ` - ${item.numberTag}`
                      : ''}
                  </h3>
                  <S.ClassificationDocList>
                    {item.documents
                      ?.sort((a, b) =>
                        (
                          a.documentData?.configData?.classification?.label ||
                          ''
                        ).localeCompare(
                          b.documentData?.configData?.classification?.label ||
                            ''
                        )
                      )
                      .map((doc, idx) => (
                        <S.ClassificationDocListItem key={idx}>
                          <p>
                            <S.CheckBox>
                              <Confirm2 />
                            </S.CheckBox>
                            {doc.documentData?.configData?.classification
                              ?.label || 'Não classificado'}
                          </p>
                        </S.ClassificationDocListItem>
                      ))}
                  </S.ClassificationDocList>
                </S.DocumentsListItem>
              ))}
          </S.DocumentsList>
          {!showForm && <S.SendDocuments validationDocument={validationDocument}>
            <S.ContentButton onClick={handleClose}>CONCLUIR</S.ContentButton>
          </S.SendDocuments>}
        </S.Content>
        <S.CloseModal onClick={handleOpenModalValidatedDocuments}>
          <Close />
        </S.CloseModal>
      </S.Container>
    </S.Section>
  )
}

export default ModalListDocuments
