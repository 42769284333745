import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  :root {
  --font-primary: 0.9rem;
  --font-secondary: 0.8rem;
  --primary-color: #a6151c;
  --secondary-color: #00a65a;
  --text-color: #373737;
  --placeholder-color: #b0b3b7;
  --border-color: #ccc;
  --background-light: #f4f4f4;
  --hover-color: #fff;
  }


  *, h1 {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  * {
    outline: 0;
  }

  body {
    font-family: 'Museo100sans';
    font-size: var(--font-primary);
    background-color: var(--background-color);
  }

  html {
    scroll-behavior: smooth;
  }

  ul {
    list-style: none;
  }

`

export default GlobalStyle
