import styled from 'styled-components'
import chroma from 'chroma-js'

export const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0 0;
`

export const Hr = styled.hr`
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
  height: 0.5px;
  width: 90%;
  margin: 0.6rem 0 1.8rem 0;
  display: ${({ showForm, validationDocument }) => {
    if (showForm) return 'flex'
    return validationDocument ? 'none' : 'flex'
  }};
`

export const WrapperSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
`

export const Title = styled.div``

export const Form = styled.div`
  width: 90%;
  margin: ${({ validationDocument }) =>
    validationDocument ? '0 0 1rem' : '0rem 0 0.5rem'};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;

  h2 {
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    line-height: 22px;
    color: ${({ isActive }) =>
      isActive ? '#373737' : chroma('#373737').alpha(0.3)};
    transition: color 0.2s ease-out;
    padding: 0 0.5rem 0 0;
  }

  hr {
    height: 3.5px;
    /* background-color: ${({ isActive }) =>
      isActive ? '#a6151c' : chroma('#a6151c').alpha(0.3)}; */
    background-color: ${({ activeTag, isActive }) => {
      if (activeTag) {
        return isActive ? '#9ca624' : chroma('#9ca624').alpha(0.3).css()
      } else {
        return isActive ? '#a6151c' : chroma('#a6151c').alpha(0.3).css()
      }
    }};
    border: none;
    border-radius: 2px;
    width: 2rem;
    transition: background-color 0.2s ease-out;
  }

  @media (max-width: 500px) {
    display: flex;
    justify-content: flex-end;
  }
`

export const BtnCopy = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  svg path {
    fill: ${({isActive}) => isActive ? '#878787' : chroma('#878787').alpha(0.3).css()};
  }
  &:hover {
    background-color: ${({isActive}) => isActive ? 'rgba(0, 0, 0, 0.1)' : 'transparent'};
  }
`

export const DataTitle = styled.div`
  width: 90%;
  margin: ${({ validationDocument }) =>
    validationDocument ? '0 0 1rem' : '0rem 0 0.5rem'};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;

  h2 {
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    line-height: 22px;
    color: ${({ isActive }) =>
      isActive ? '#373737' : chroma('#373737').alpha(0.3)};
    transition: color 0.2s ease-out;
  }

  hr {
    height: 3.5px;
    /* background-color: ${({ isActive }) =>
      isActive ? '#a6151c' : chroma('#a6151c').alpha(0.3)}; */
    background-color: ${({ activeTag, isActive }) => {
      if (activeTag) {
        return isActive ? '#9ca624' : chroma('#9ca624').alpha(0.3).css()
      } else {
        return isActive ? '#a6151c' : chroma('#a6151c').alpha(0.3).css()
      }
    }};
    border: none;
    border-radius: 2px;
    width: 2rem;
    transition: background-color 0.2s ease-out;
  }

  @media (max-width: 500px) {
    display: flex;
    justify-content: flex-end;
  }
`

export const Content = styled.div`
  width: 90%;
`
