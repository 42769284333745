import React, { useContext, useEffect, useState, useRef } from 'react'
import * as S from './styles'
import { DocsContext } from '../../context/docs-context'
import chroma from 'chroma-js'
import { ReactComponent as CheckList } from '../../assets/image/checklist.svg'
import { ReactComponent as IA } from '../../assets/image/STARS-IAIIA.svg'
import { ReactComponent as IAConfirm } from '../../assets/image/ia-ok.svg'
import { ReactComponent as Confirm } from '../../assets/image/ok-review.svg'
import { ReactComponent as Save } from '../../assets/image/check-list-save2.svg'
import { defaultColors } from '../../constant/documentstype'
import useClickOutside from '../../hook/useClickOutside'
import { toast } from 'react-toastify'

const DocumentRatingParts = (props) => {
  const {
    idFileDoc,
    partFormListState,
    handlePartForm,
    activeTag,
    filterSelectedColor,
    handleConfigData,
    configData,
    handleClassificationByForm,
    showForm,
    tagsWithColors,
    setTagsWithColors,
    documentsPartArray,
    handleActiveTag,
    dataDocumentToLint,
    showContractPart,
    validationDocument,
    showClassification,
    handleFormDataToAi,
    handleOpenModalValidatedDocuments,
    showLabelText,
    labelTag,
    handleTagSelected,
    partSelected,
    showListDocuments,
    handleDataDocumentToLint,
    updatedDataDocumentToLint,
    isLoadingForm,
    generateRandomColor,
  } = useContext(DocsContext)

  const [validatedfilterdocument, setValidatedFilterDocument] = useState()

  //eslint-disable-next-line no-unused-vars
  const [resetTagSelected, setResetTagSelected] = useState(false)
  const [openList, setOpenList] = useState(false)
  const [validatedDocument, setValidatedDocument] = useState({})
  const [newTagsAdd, setNewTagsAdd] = useState([])
  const [openNewTaggAdd, setOpenNewTaggAdd] = useState(false)
  //eslint-disable-next-line no-unused-vars
  const [dropdownPosition, setDropdownPosition] = useState('right')
  const AddNewTagListRef = useRef(null)

  useEffect(() => {
    const documentToValidated = configData?.documents?.find(
      (item) => item.documentId === idFileDoc
    )
    setValidatedFilterDocument(documentToValidated)
  }, [idFileDoc, configData?.documents])

  useEffect(() => {
    if (idFileDoc && configData?.documents && tagsWithColors) {
      const documentToTag = configData.documents.find(
        (item) => item?.documentId === idFileDoc
      )
      const documentToTagWithNumberTag = {
        ...documentToTag,
        numberTag: documentToTag?.numberTag ?? 1,
      }

      if (documentToTagWithNumberTag?.part) {
        const findTagColor = tagsWithColors.find(
          (item) =>
            item?.part === documentToTagWithNumberTag?.part &&
            item?.numberTag === documentToTagWithNumberTag?.numberTag
        )
        handleTagSelected(findTagColor || documentToTagWithNumberTag)
      } else {
        handleTagSelected(documentToTagWithNumberTag)
      }
    }
  }, [idFileDoc, configData?.documents])

  useEffect(() => {
    const documentToValidated = configData?.documents.find(
      (item) => item?.documentId === idFileDoc
    )
    setValidatedDocument(documentToValidated)
  }, [idFileDoc, configData?.documents])

  useEffect(() => {
    const documentTags = (configData?.documents || []).filter(
      (item) => item.part
    )

    const uniqueTags = new Set()
    const documentTagPart = documentTags.reduce((acc, item) => {
      const uniqueKey = `${item.part}-${item.numberTag ?? 1}`
      if (!uniqueTags.has(uniqueKey)) {
        uniqueTags.add(uniqueKey)
        acc.push({
          part: item.part,
          numberTag: item.numberTag ?? 1,
        })
      }
      return acc
    }, [])

    const tags = (configData?.tags || []).map((item) => ({
      part: item,
      numberTag: item.numberTag ?? 1,
    }))

    const extraTag = {
      part: labelTag?.part || null,
      color: labelTag?.color || '#ccc',
      numberTag: null,
      tagAdd: 'extraTag',
    }

    let allTags = [...documentTagPart, ...tags]
    const isExtraTagPresent = allTags.find(
      (item) =>
        item.part === extraTag.part && item.numberTag === extraTag.numberTag
    )

    if (!isExtraTagPresent && extraTag.part) {
      allTags.push(extraTag)
    }

    const tagUniques = allTags
      .reduce((acc, item) => {
        if (
          !acc.some(
            (existingItem) =>
              existingItem.part === item.part &&
              existingItem.numberTag === item.numberTag
          )
        ) {
          acc.push({
            part: item.part,
            numberTag: item.part === extraTag.part ? null : item.numberTag,
          })
        }
        return acc
      }, [])
      .sort((a, b) => {
        const tagOrder = (a.part || '').localeCompare(b.part || '')
        if (tagOrder === 0) {
          return (a.numberTag ?? 1) - (b.numberTag ?? 1)
        }
        return tagOrder
      })

    const transformedTags = tagUniques
      .filter((tag) => tag.part || tag.part !== null)
      .map((tag, index) => {
        const colorIndex = index % defaultColors.length
        const color =
          colorIndex < defaultColors.length
            ? defaultColors[colorIndex]?.color
            : generateRandomColor()

        if (tag.part === extraTag.part) {
          return {
            id: tagsWithColors.length,
            part: tag?.part,
            color,
            numberTag: 1,
            tagAdd: extraTag?.tagAdd,
          }
        }

        return {
          id: tagsWithColors.length,
          part: tag.part,
          color,
          numberTag: tag.numberTag ?? 1,
          tagAdd: tag?.tagAdd,
        }
      })

    setTagsWithColors((prevTags) => {
      const mergedTags = [...prevTags]

      transformedTags.forEach((newTag) => {
        const tagExists = prevTags.some(
          (prevTag) =>
            prevTag.part === newTag.part &&
            prevTag.numberTag === newTag.numberTag
        )
        if (!tagExists) {
          mergedTags.push(newTag)
        }
      })

      return mergedTags
    })
  }, [configData, generateRandomColor, labelTag])

  useEffect(() => {
    // console.log('who is partSelected?', partSelected);
  }, [partSelected])

  const handlePartsContract = (tag, index) => {
    if (validatedDocument?.validated) {
      return
    }
    if (
      partSelected?.index === index &&
      (partSelected?.numberTag === tag.numberTag || tag.tagAdd === 'extratag')
    ) {
      handleTagSelected({})
    } else {
      handleTagSelected({ ...tag, index })
    }
  }

  const handleAddNewTag = () => {
    if (validatedDocument?.validated) {
      return
    }

    setOpenNewTaggAdd(!openNewTaggAdd)

    if (!openNewTaggAdd) {
      const partMap = new Map()

      const documentsWithTagAdd = documentsPartArray.map((tag) => ({
        ...tag,
        tagAdd: tag.tagAdd ?? '',
        numberTag: tag.numberTag ?? 1,
      }))

      documentsWithTagAdd.forEach((tag) => {
        if (partMap.has(tag.part)) {
          return
        }

        const existingTags = tagsWithColors?.filter(
          (t) => t?.part === tag?.part && t?.numberTag === tag?.numberTag
        )

        const newNumberTag = tag.numberTag + 1

        const colorIndex = tagsWithColors.length % defaultColors.length
        const colorTagDefaultColors = defaultColors[colorIndex]

        const color =
          tagsWithColors.length >= defaultColors.length
            ? generateRandomColor()
            : colorTagDefaultColors.color

        partMap.set(tag.part, {
          ...tag,
          numberTag: newNumberTag,
          color: color,
        })
      })

      const newPartContractAdd = Array.from(partMap.values())

      const filteredTags = newPartContractAdd.filter(
        (tag) =>
          tag.part !== 'Validados' &&
          tag.part !== 'Não Cadastrados' &&
          tag.part !== 'Pendentes' &&
          tag.tagAdd !== 'extraTag'
      )

      setNewTagsAdd(filteredTags)
    }
  }

  useEffect(() => {
    // console.log('TagsWithColors', tagsWithColors);
  }, [tagsWithColors])
  const addTagToTagWithColors = (tag) => {
    handleTagSelected(tag)
    setOpenNewTaggAdd(false)
    setNewTagsAdd([])
    setTagsWithColors((prevTags) => {
      const tagExists = prevTags.some(
        (prevTag) =>
          prevTag.part === tag.part && prevTag.numberTag === tag.numberTag
      )

      if (tagExists) {
        return prevTags
      } else {
        return [...prevTags, tag]
      }
    })
  }

  useClickOutside(AddNewTagListRef, () => setOpenNewTaggAdd(false))

  // useEffect(() => {
  //   console.log('documenttolint', dataDocumentToLint)
  // }, [dataDocumentToLint])

  useEffect(() => {
    // console.log('useEffect activeTag::', activeTag)
    // console.log(
    //   'validatedfilterdocument?.validated',
    //   validatedfilterdocument?.validated
    // )
    console.log('isLoadingForm', isLoadingForm)
  }, [isLoadingForm])

  return (
    <S.SectionRatingParts
      showForm={showForm}
      showLabelText={showLabelText}
      validationDocument={validationDocument}
      showClassification={showClassification}
      openList={openList}
      showContractPart={showContractPart}
    >
      <S.ClassificationDoctype>
        <S.PartsAdd>
          <S.Content validationDocument={validationDocument}>
            <div>
              <h2>{showLabelText}:</h2>
              {!validationDocument && !showForm ? (
                <S.TagParts color={partSelected.color}>
                  {partSelected.part
                    ? `${partSelected.part} ${
                        partSelected.part === labelTag.part
                          ? ''
                          : partSelected.numberTag ?? 1
                      }`
                    : 'Não cadastrado'}
                </S.TagParts>
              ) : null}
            </div>
            {!showForm && showListDocuments && (
              <S.BtnResumeDocs
                onClick={() => {
                  handleOpenModalValidatedDocuments()
                }}
                title={'Resumir Documentos'}
              >
                <CheckList />
              </S.BtnResumeDocs>
            )}
            {showForm && (
              <S.WrapperButtonIa activeTag={activeTag?.dataValidated}>
                <button
                  data-activetag={activeTag?.dataValidated}
                  data-isloadingform={isLoadingForm}
                  title={'Preencher com IA'}
                  onClick={handleFormDataToAi}
                >
                  {isLoadingForm || activeTag?.dataValidated ? (
                    <IAConfirm />
                  ) : (
                    <IA />
                  )}
                </button>
              </S.WrapperButtonIa>
            )}
          </S.Content>
        </S.PartsAdd>
        {!showForm && (
          <S.PartList validationDocument={validationDocument}>
            {tagsWithColors
              ?.slice()
              .sort((a, b) => (a.part || '').localeCompare(b.part || ''))
              .map((tag, index) => (
                <S.PartListItem
                  validatedDocument={validatedDocument?.validated}
                  key={index}
                  color={
                    tag?.part === partSelected?.part &&
                    (tag.tagAdd === 'extraTag' ||
                      tag.numberTag === partSelected?.numberTag)
                      ? chroma.valid(tag?.color)
                        ? `${chroma(tag?.color)}`
                        : 'transparent'
                      : chroma.valid(tag?.color)
                      ? `${chroma(tag?.color).alpha(0.2)}`
                      : chroma('transparent').alpha(0.2)
                  }
                  onClick={() => handlePartsContract(tag, index)}
                  isSelected={
                    tag.part === partSelected?.part &&
                    (tag.tagAdd === 'extraTag' ||
                      tag.numberTag === partSelected?.numberTag)
                  }
                >
                  {tag.part}
                  {tag.tagAdd !== 'extraTag' && ` ${tag.numberTag}`}
                </S.PartListItem>
              ))}
            {documentsPartArray.length > 0 && (
              <S.ContentList>
                <S.AddNewTag
                  ref={AddNewTagListRef}
                  documentsPartArray={documentsPartArray}
                  onClick={handleAddNewTag}
                  validatedDocument={validatedDocument?.validated}
                >
                  {openNewTaggAdd ? '-' : '+'}
                  <S.AddNewTagList
                    ref={AddNewTagListRef}
                    dropdownPosition={dropdownPosition}
                    onClick={() => setOpenNewTaggAdd(!openNewTaggAdd)}
                    openNewTaggAdd={openNewTaggAdd}
                    validatedDocument={validatedDocument?.validated}
                  >
                    {newTagsAdd
                      ?.filter((item) => item.tagAdd !== 'extraTag')
                      .slice()
                      .sort((a, b) =>
                        (a.part || '').localeCompare(b.part || '')
                      )
                      .map((item, index) => (
                        <S.AddNewTagListItem
                          key={index}
                          color={item.color}
                          onClick={() => addTagToTagWithColors(item)}
                          validatedDocument={validatedDocument?.validated}
                        >
                          {item?.part} {item?.numberTag}
                        </S.AddNewTagListItem>
                      ))}
                  </S.AddNewTagList>
                </S.AddNewTag>
              </S.ContentList>
            )}
          </S.PartList>
        )}
        {showForm && (
          <S.PartFormList>
            {partFormListState
              ?.filter((item) => item?.part)
              ?.sort((a, b) => a.part?.localeCompare(b?.part))
              ?.map((item, index) => {
                if (item.part === 'Não Cadastrados' && validationDocument) {
                  return null
                }
                if (item.part === 'Validados' || item.part === 'Pendentes') {
                  return null
                }
                return (
                  <S.PartFormListItem
                    validatedfilterdocument={validatedfilterdocument?.validated}
                    dataValidatedfilterdocument={activeTag?.dataValidated}
                    key={index}
                    onClick={() => handleActiveTag(item)}
                    isActive={
                      item?.part === activeTag?.part &&
                      item?.numberTag === activeTag?.numberTag
                    }
                  >
                    {item?.part}{' '}
                    {item?.numberTag !== null &&
                      item?.numberTag !== undefined &&
                      item?.tagAdd !== 'extraTag' &&
                      ` ${item?.numberTag}`}
                    <S.Badge
                      validatedFormItem={item.dataValidated}
                      isActive={
                        item?.part === activeTag?.part &&
                        item?.numberTag === activeTag?.numberTag
                      }
                    >
                      <Confirm />
                    </S.Badge>
                  </S.PartFormListItem>
                )
              })}
          </S.PartFormList>
        )}
      </S.ClassificationDoctype>
    </S.SectionRatingParts>
  )
}

export default DocumentRatingParts
