import React, { useContext, useState, useEffect } from 'react'
import * as S from './styles'
import './styles.css'
import ProgressBar from '../../components/progress-bar/ProgressBar'
import DocumentValidation from '../../components/document-validation/DocumentValidation'
import DocumentRating from '../../components/document-rating/DocumentRating'
import DocumentListComponent from '../../components/document-list-component'
import PreviewDocs from '../../components/preview-docs'
import BtnValidateDoc from '../../components/btn-validate-doc/BtnValidateDoc'
import { contractParties, documentstype } from '../../constant/documentstype'
import { DocsContext } from '../../context/docs-context'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import DocumentRatingParts from '../../components/document-rating-parts'
import ModalListDocuments from '../../components/modal-list-documents'
import ErrorComponent from '../../components/error-component'
import DocumentReview from '../DocumentReview/DocumentReview'
import ListFormDocuments from '../../components/list-form-documents'

const App = () => {
  const {
    fileId,
    collection,
    access_token,
    activeTag,
    previewSize,
    showForm,
    showError,
    openDocumentPreview,
    handleErrorDocBox,
  } = useContext(DocsContext)
  const [isVertical, setIsVertical] = useState(window.innerWidth < 1022)

  useEffect(() => {
    console.log("activeTag", activeTag)
  }, [activeTag])

  useEffect(() => {
    /**
     * Interceptando todos os FETCHS e se na URL contiver boxcloud,
     * então forçar que o navegador não armazene a resposta em memória
     * (não coloque no cache).
     * */
    const { fetch: originalFetch } = window
    window.fetch = async (...args) => {
      let [resource, options] = args
      const response = await originalFetch(resource, options)
      if (response.status === 500) {
        handleErrorDocBox(response.url)
      }
      if (args[0].includes('boxcloud')) {
        if (response.status !== 200 && response.status !== 302) {
          options.cache = 'no-cache'
          const response = await originalFetch(resource, options)
          return response
        }
      }
      return response
    }

    const handleResize = () => {
      setIsVertical(window.innerWidth < 1022)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleErrorDocBox /*appInsights*/])

  const handleValidationClick = () => {}

  const handleDocumentRating = (ratingValue, docId) => {}

  const previewerProps = {
    logoUrl: 'box',
    contentSidebarProps: false,
    token: access_token,
    fileId: fileId,
    showAnnotations: true,
    collection: collection,
  }

  const classificationDocs = {
    label: 'Classificação',
    docTypes: documentstype,
  }

  const classificationContractParties = {
    label: 'Partes Contratantes',
    docTypes: contractParties,
  }

  return (
    <>
      {openDocumentPreview ? (
        <>
          <DocumentReview />
        </>
      ) : showError ? (
        <ErrorComponent />
      ) : isVertical ? (
        <S.SectionVertical>
          <S.Docs>
            <ProgressBar />
            <PreviewDocs {...previewerProps} />
          </S.Docs>
          <S.DocInfo>
            <DocumentValidation />
            <DocumentRating
              {...classificationDocs}
              onRating={handleDocumentRating}
            />
            <DocumentRatingParts />
            <BtnValidateDoc onClick={handleValidationClick} />
            <ModalListDocuments />
            {showForm ? (
              <ListFormDocuments />
            ) : (
              <DocumentListComponent fileId={fileId} collection={collection} />
            )}
          </S.DocInfo>
        </S.SectionVertical>
      ) : previewSize.length > 0 ? (
        <PanelGroup
          style={{ height: `calc(100vh - 64px)` }}
          direction="horizontal"
          autoSaveId="example"
        >
          {/* <Panel defaultSize={previewSize[0]}> */}
          <Panel defaultSize={35}>
            <S.Docs>
              <ProgressBar />
              <PreviewDocs
                {...previewerProps}
                style={{ height: `calc(100vh - 234px)` }}
              />
            </S.Docs>
          </Panel>
          <PanelResizeHandle className="resize" />
          {/* <Panel defaultSize={previewSize[1]}> */}
          <Panel defaultSize={15}>
            <S.DocInfo showForm={showForm}>
              <DocumentValidation />
              <DocumentRating
                {...classificationDocs}
                onRating={handleDocumentRating}
              />
              <ModalListDocuments />
              <DocumentRatingParts {...classificationContractParties} />
              <BtnValidateDoc onClick={handleValidationClick} />
              {showForm ? (
                <ListFormDocuments />
              ) : (
                <DocumentListComponent
                  fileId={fileId}
                  collection={collection}
                />
              )}
            </S.DocInfo>
          </Panel>
        </PanelGroup>
      ) : null}
    </>
  )
}

export default App
