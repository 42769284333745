import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'

const reactPlugin = new ReactPlugin()

const instrumentationKey = process.env.REACT_APP_INSTRUMENTATION_KEY
const browserHistory = createBrowserHistory({ basename: '' })
let appInsights

const getAppInsights = () => {
  if (!appInsights) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
        enableAutoRouteTracking: true,
      },
    })

    appInsights.loadAppInsights()
  }

  return appInsights
}

export { reactPlugin, getAppInsights }
