import React, { useEffect, useState, useContext, useRef } from 'react'
import * as S from './styles'
import { toast } from 'react-toastify'
import FilterValidated from '../../assets/image/filter-list-validated.svg'
import { DocsContext } from '../../context/docs-context'
import FilterIcon from '../filter-icon'
import useClickOutside from '../../hook/useClickOutside'
import { groupDocuments } from '../../utils/utils'

const FilterByContractingParts = () => {
  const {
    configData,
    documentListData,
    idFileDoc,
    validatedDocument,
    collection,
    tagsWithColors,
    handleFilter,
    handleClearFilter,
    filterSelectedColor,
    handleDocumentsPartArray,
    documentsPartArray,
    validationDocument,
  } = useContext(DocsContext)
  const FilterListRef = useRef(null)
  const [openFilterList, setOpenFilterList] = useState(false)
  const [openDataFilter, setOpenDataFilter] = useState(false)
  const [validatedfilterdocument, setValidatedFilterDocument] = useState()

  useEffect(() => {
    const documentToValidated = configData?.documents?.find(
      (item) => item.documentId === idFileDoc
    )
    setValidatedFilterDocument(documentToValidated)
  }, [idFileDoc, configData?.documents])

  useEffect(() => {
    if (configData?.documents) {
      const finalDocumentsArray = groupDocuments(
        configData?.documents,
        tagsWithColors
      )
      handleDocumentsPartArray(finalDocumentsArray)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData?.documents, tagsWithColors, collection])

  const handleFilterItem = (item) => {
    if (!item || !item.documentId || item.documentId.length <= 0) {
      const partLowerCase = item?.part?.toLowerCase() || 'Parte não definida'
      return toast.error(
        `Nenhum documento ${
          partLowerCase.endsWith('s')
            ? partLowerCase.slice(0, -1)
            : partLowerCase
        } encontrado!`,
        {
          theme: 'light',
        }
      )
    }
    if (
      filterSelectedColor &&
      item.part === filterSelectedColor.part &&
      item.numberTag === filterSelectedColor.numberTag
    ) {
      handleFilter()
      handleClearFilter()
    } else {
      handleFilter(item)
      setOpenDataFilter(true)
    }

    setOpenFilterList(false)
  }

  useClickOutside(FilterListRef, () => setOpenFilterList(false))

  return (
    <S.Section ref={FilterListRef}>
      {documentsPartArray.length > 0 && (
        <S.HasFilter
          onClick={() => setOpenFilterList(!openFilterList)}
          filterSelectedColor={filterSelectedColor}
          openFilterList={openFilterList}
          title={'Filtrar'}
          validatedfilterdocument={validatedfilterdocument?.validated}
        >
          {!validatedDocument?.validated ? (
            <>
              <FilterIcon
                filterSelectedColor={filterSelectedColor}
                validatedfilterdocument={validatedfilterdocument?.validated}
              />
              {filterSelectedColor && (
                <S.FilterData openDataFilter={openDataFilter}>
                  <S.FilterNotification
                    color={filterSelectedColor?.color}
                  ></S.FilterNotification>
                  <h4>Filtro</h4>
                  <strong>
                    {collection?.length ?? 0}/
                    {documentListData?.length ?? 0}
                  </strong>
                </S.FilterData>
              )}
            </>
          ) : (
            <img src={FilterValidated} alt="filtro" />
          )}
        </S.HasFilter>
      )}
      <S.FilterList openFilterList={openFilterList} ref={FilterListRef}>
        {documentsPartArray
          .sort((a, b) => {
            if (a.part === 'Validados' || a.part === 'Pendentes') return 1
            if (b.part === 'Validados' || b.part === 'Pendentes') return -1
            return a.part?.localeCompare(b.part)
          })
          .map((item, index) => {
            if (item.part === 'Não Cadastrados' && validationDocument) {
              return null
            }
            const isSelected =
              filterSelectedColor &&
              item.part === filterSelectedColor.part &&
              item.numberTag === filterSelectedColor.numberTag
            return (
              <S.FilterListItem
                validatedfilterdocument={validatedfilterdocument?.validated}
                filterSelectedColor={item.color}
                key={index}
                onClick={() => handleFilterItem(item)}
                color={isSelected ? item.color : '#fff'}
                textColor={
                  isSelected
                    ? '#fff'
                    : validatedfilterdocument?.validated
                    ? '#9ca624'
                    : '#a6151c'
                }
                isSelected={isSelected}
              >
                {/* {item.part} {item.tagAdd !== 'extraTag' && ` ${item.numberTag}`} */}
                {item.part}{' '}
                {item.numberTag !== null &&
                  item.numberTag !== undefined &&
                  item.tagAdd !== 'extraTag' &&
                  ` ${item.numberTag}`}
              </S.FilterListItem>
            )
          })}
      </S.FilterList>
    </S.Section>
  )
}

export default FilterByContractingParts
