import React, { useContext, useEffect, useState } from 'react'
import * as S from './styles'
import { DocsContext } from '../../context/docs-context'
import { ReactComponent as Copy } from '../../assets/image/copy-review.svg'
import FormComponents from '../form-components'
import DocumentSummary from '../document-summary'
import { getAppInsights } from '../../insights/app-insights'

const ListFormDocuments = () => {
  const {
    showForm,
    validationDocument,
    activeTag,
    documentsPartArray,
    handleCopyToClipboard,
  } = useContext(DocsContext)
  const [activeSection, setActiveSection] = useState('form')
  const [formData, setFormData] = useState({ data: {} })

  const appInsights = getAppInsights()

  const handleSectionClick = (section) => {
    setActiveSection(section)
  }

  const handleCopy = () => {
    handleCopyToClipboard(formData)
    try {
      appInsights &&
        appInsights.trackEvent({
          name: 'Dados copiados com sucesso',
        })
    } catch (error) {
      appInsights && appInsights.trackException({ error: new Error(error) })
    }
  }

  const updateFormData = (data) => {
    console.log('data', data)
    setFormData(data)
  }

  useEffect(() => {
    // console.log(`ìs active no listform`, documentsPartArray);
  }, [])

  return (
    <S.Section>
      {/* <S.Hr showForm={showForm} /> */}
      <S.WrapperSection>
        <S.Form
          activeTag={activeTag?.dataValidated}
          isActive={activeSection === 'form'}
          onClick={() => handleSectionClick('form')}
        >
          <S.Title>
            <h2>FORMULÁRIO</h2>
            <hr />
          </S.Title>
          <S.BtnCopy
            isActive={activeSection === 'form'}
            title={'Copiar os dados'}
            onClick={() => {
              if (activeSection === 'form') {
                handleCopyToClipboard(formData)
              }
            }}
          >
            <Copy />
          </S.BtnCopy>
        </S.Form>
        <S.DataTitle
          activeTag={activeTag?.dataValidated}
          isActive={activeSection === 'summary'}
          onClick={() => handleSectionClick('summary')}
        >
          <h2>DADOS EXTRAÍDOS</h2>
          <hr />
        </S.DataTitle>
      </S.WrapperSection>
      <S.Content>
        {activeSection === 'form' ? (
          <FormComponents onUpdateFormData={updateFormData} />
        ) : (
          <DocumentSummary />
        )}
      </S.Content>
    </S.Section>
  )
}

export default ListFormDocuments
