import React, { useContext, useState, useEffect } from 'react'
import * as S from './styles'
import { DocsContext } from '../../context/docs-context'
import { groupDocuments } from '../../utils/utils'
// import { ReactComponent as Confirm } from '../../assets/image/ok-review.svg'
import { toast } from 'react-toastify'
import { getAppInsights } from '../../insights/app-insights'

const BtnValidateDoc = () => {
  const {
    handlePartFormUpdate,
    configData,
    tagRequiredFile,
    handleAnalyzerData,
    idFileDoc,
    documentsPartArray,
    partFormListState,
    showForm,
    startingLoading,
    validationDocument,
    analyzerDataList,
    handleSelectedDocument,
    showContractPart,
    activeTag,
    handleConfigData,
    showClassification,
    showLabelText,
    sendNewDocument,
    updatedFilterSelected,
    tagsWithColors,
    partSelected,
    handleUpdateActiveTag,
    handleDocumentsPartArray,
    handleClassification,
  } = useContext(DocsContext)

  const appInsights = getAppInsights()

  const [validatedDocument, setValidatedDocument] = useState({})
  const [updateTriggered, setUpdateTriggered] = useState(false)

  useEffect(() => {
    const documentToValidated = configData?.documents?.find(
      (item) => item.documentId === idFileDoc
    )
    if (documentToValidated && partSelected) {
      const updatedDocumentToValidated = {
        ...documentToValidated,
        part: partSelected.part,
        numberTag: partSelected.numberTag,
        color: partSelected.color,
        tagAdd: partSelected.tagAdd,
      }
      setValidatedDocument(updatedDocumentToValidated)
    }
  }, [idFileDoc, configData?.documents, partSelected])

  const handleUpdate = async () => {
    let titulo
    let updatedDocument = null
    if (tagRequiredFile && !validatedDocument?.part) {
      return toast.error(`Selecione ${showLabelText}`, {
        theme: 'light',
      })
    }
    if (!validatedDocument?.classification?.label) {
      return toast.error(
        `Por favor, classifique o documento antes de continuar!`,
        {
          theme: 'light',
        }
      )
    }

    const updatedAnalyzerDataList = analyzerDataList?.map((item) => {
      if (item?.retorno?.idDocBox === idFileDoc) {
        if (validatedDocument?.classification?.titulo && item?.retorno?.dados) {
          titulo =
            item.retorno.dados[validatedDocument.classification.titulo] || null
        } else {
          titulo = null
        }

        return {
          ...item,
          retorno: {
            ...item.retorno,
            validado: !item.retorno?.validado,
          },
        }
      }
      return item
    })

    const updatedDocuments = configData.documents.map((doc) => {
      if (doc.documentId === idFileDoc) {
        updatedDocument = {
          ...doc,
          part: partSelected.part,
          numberTag: partSelected.numberTag ?? 1,
          validated: !doc.validated,
        }

        if (updatedDocument.classification) {
          updatedDocument.classification.tituloValue = titulo
        }

        return updatedDocument
      }
      return doc
    })

    document.body.style.cursor = 'wait'

    try {
      const response = await sendNewDocument(updatedDocument)
      if (!response.ok) {
        throw new Error('Erro ao tentar validar o documento no servidor.')
      }

      handleConfigData(updatedDocuments)

      const updatedValidatedDocument = {
        ...validatedDocument,
        part: partSelected.part,
        numberTag: partSelected.numberTag,
        validated: !validatedDocument?.validated,
      }

      setValidatedDocument(updatedValidatedDocument)
      handleAnalyzerData(updatedAnalyzerDataList)

      const selectedItem = updatedAnalyzerDataList?.find(
        (item) => item?.retorno.idDocBox === idFileDoc
      )

      handleSelectedDocument(selectedItem)

      setUpdateTriggered(true)

      if (updatedValidatedDocument.validated) {
        handleClassification(updatedValidatedDocument)
        try {
          appInsights &&
            appInsights.trackEvent({
              name: 'Documento classificado com sucesso',
              properties: {
                updatedValidatedDocument,
              },
            })
        } catch (error) {
          appInsights && appInsights.trackException({ error: new Error(error) })
        }
        toast.success(`Documento classificado com sucesso!`, {
          theme: 'light',
          progressStyle: { backgroundColor: '#9ca624' },
          style: {
            background: '#f4f4f4',
          },
        })
      } else {
        toast.info(`Classificação do documento removida com sucesso!`, {
          theme: 'light',
        })
      }
    } catch (error) {
      console.error('Erro na requisição:', error)
      toast.error(`Erro ao validar o documento. Tente novamente.`, {
        theme: 'light',
      })
    } finally {
      document.body.style.cursor = 'default'
    }
  }

  useEffect(() => {
    if (updateTriggered) {
      if (configData?.documents) {
        const finalDocumentsArray = groupDocuments(
          configData?.documents,
          tagsWithColors
        )
        updatedFilterSelected(finalDocumentsArray)
        if (!showForm) {
          handleDocumentsPartArray(finalDocumentsArray)
        }
        setUpdateTriggered(false)
      }
    }
  }, [
    updateTriggered,
    configData?.documents,
    tagsWithColors,
    updatedFilterSelected,
    handleDocumentsPartArray,
  ])

  return (
    <S.Section
      showForm={showForm}
      validationDocument={validationDocument}
      showContractPart={showContractPart}
      showClassification={showClassification}
    >
      <S.ButtonValidateDoc
        showForm={showForm}
        startingLoading={startingLoading}
        dataValidaded={activeTag?.dataValidated}
        validatedDocument={validatedDocument?.validated}
        onClick={() => {
          if (!showForm) {
            handleUpdate()
          } else {
            handlePartFormUpdate(activeTag)
          }
        }}
      >
        {showForm
          ? activeTag?.dataValidated
            ? 'Invalidar Dados'.toUpperCase()
            : 'Validar Dados'.toUpperCase()
          : validatedDocument?.validated
          ? 'Desfazer Classificação'.toUpperCase()
          : 'Classificar Documentação'.toUpperCase()}
      </S.ButtonValidateDoc>
    </S.Section>
  )
}

export default BtnValidateDoc
