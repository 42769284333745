import getToken from './tokenManager'

const fetchWithAuth = async (url, options = {}) => {
  try {
    const token = await getToken()

    const headers = new Headers(options.headers || {})
    headers.append('Authorization', `Bearer ${token}`)

    const newOptions = {
      ...options,
      headers,
    }

    return fetch(url, newOptions)
  } catch (error) {
    console.error('Error fetching with auth', error)
    throw error
  }
}

export default fetchWithAuth
