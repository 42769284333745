import React, { useContext, useState, useEffect, useRef } from 'react'
import * as S from './styles'
import CopyDataImage from '../../assets/image/copy2.svg'
import { ReactComponent as Error } from '../../assets/image/error-circle.svg'
import { DocsContext } from '../../context/docs-context'
import { ReactComponent as ArrowRight } from '../../assets/image/double-arrow.svg'
import CopyValue from '../../assets/image/copy-ok.svg'
import insertSpaceBetweenWords from '../../utils/utils'
import PropagateLoader from 'react-spinners/PropagateLoader'
import ModalAllListDataDocuments from '../modal-all-list-data-documents/ModalAllListDataDocuments'

const override = {
  display: 'block',
  margin: '2rem 0',
  borderColor: 'red',
}

const DocumentListComponent = () => {
  const [validatedDocument, setValidatedDocument] = useState({})
  //eslint-disable-next-line no-unused-vars
  const [loadingDocument, setLoadingDocument] = useState(true)
  const {
    idFileDoc,
    validationDocument,
    configData,
    showForm,
    analyzerDataList,
    handleOpenModalAllLists,
    startingLoading,
    errorDocBox,
  } = useContext(DocsContext)
  const [copiedText, setCopiedText] = useState('')
  const [documentsInfo, setDocumentsInfo] = useState({})
  const [openCopyButton, setOpenCopyButton] = useState(false)
  const [isUndefinedOnLoad, setIsUndefinedOnLoad] = useState(false)
  const timeoutRef = useRef(null)

  const color = '#ccc'
  const loading = true

  const findDocumentById = (id) => {
    let foundDocument = analyzerDataList?.find(
      (item) => item?.retorno && item?.retorno.idDocBox === id
    )

    if (!foundDocument) {
      foundDocument = { retorno: { dados: {} } }
    }

    return foundDocument
  }

  const foundDocument = findDocumentById(idFileDoc)

  useEffect(() => {
    setLoadingDocument(true)
    const foundDocument = findDocumentById(idFileDoc)
    setDocumentsInfo(foundDocument.retorno.dados || {})
    setLoadingDocument(false)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idFileDoc, analyzerDataList])

  useEffect(() => {
    const documentToValidated = configData?.documents.find(
      (item) => item.documentId === idFileDoc
    )
    setValidatedDocument(documentToValidated)
  }, [idFileDoc, configData?.documents])

  useEffect(() => {
    if (!foundDocument) {
      const foundId = errorDocBox.find((docId) => docId === idFileDoc)
      if (foundId) {
        setIsUndefinedOnLoad(true)
      }
    }

    if (foundDocument) {
      setIsUndefinedOnLoad(false)
    }
  }, [idFileDoc, analyzerDataList, foundDocument, errorDocBox])

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const copyToClipboard = (text) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedText(text)
        setOpenCopyButton(true)

        timeoutRef.current = setTimeout(() => {
          setOpenCopyButton(false)
          setCopiedText(null)
        }, 950)
      })
      .catch((err) => {
        //console.error('Falha ao copiar texto: ', err);
      })
  }

  const hasListData =
    foundDocument?.retorno?.listas &&
    Object.keys(foundDocument.retorno.listas).length > 0

  return (
    <>
      <S.Hr showForm={showForm} validationDocument={validationDocument} />
      <S.DataTitle
        validationDocument={validationDocument}
        docValidated={validatedDocument?.validated}
      >
        <h2>DADOS</h2>
        <hr />
      </S.DataTitle>

      {startingLoading ? (
        <PropagateLoader
          color={color}
          loading={startingLoading}
          cssOverride={override}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : isUndefinedOnLoad ? (
        <S.ContentNoInfo>
          <S.Content>
            <p>
              <span>
                <Error />
              </span>
              Este documento não possui classificação. Por favor, classifique-o
              para continuar.
            </p>
          </S.Content>
        </S.ContentNoInfo>
      ) : foundDocument?.retorno ? (
        documentsInfo && Object.keys(documentsInfo).length > 0 ? (
          <S.DataList
            docValidated={foundDocument?.validado}
            validatedDocument={validatedDocument}
            validationDocument={validationDocument}
            validated={validatedDocument?.validated}
          >
            {Object.entries(documentsInfo)
              ?.filter(([key]) => !/hidden/i.test(key))
              .sort(([keyA], [keyB]) => keyA?.localeCompare(keyB))
              .map(([key, value], index) => (
                <S.DataListItem key={index}>
                  <p>
                    <strong>{insertSpaceBetweenWords(key || '')}:</strong>
                    <span>{value}</span>
                  </p>
                  <S.ConfirmCopyValue
                    openCopyButton={openCopyButton}
                    visible={copiedText === value}
                  >
                    <img src={CopyValue} alt="valor copiado" />
                    Copiado
                  </S.ConfirmCopyValue>
                  <S.BtnCopy
                    title={'Copiar'}
                    onClick={() => copyToClipboard(value)}
                  >
                    <img src={CopyDataImage} alt="icon" />
                  </S.BtnCopy>
                </S.DataListItem>
              ))}
            {hasListData && (
              <>
                <S.BtnOpenDataDocs
                  onClick={() => {
                    handleOpenModalAllLists()
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  VER MAIS
                  <S.SeeMore>
                    <ArrowRight />
                  </S.SeeMore>
                </S.BtnOpenDataDocs>
                <ModalAllListDataDocuments
                  additionalInformation={foundDocument.retorno.listas}
                />
              </>
            )}
          </S.DataList>
        ) : (
          <S.ContentNoInfo>
            <S.Content>
              <p>
                <span>
                  <Error />
                </span>
                Não há informações disponíveis para esta classificação no
                momento.
              </p>
            </S.Content>
          </S.ContentNoInfo>
        )
      ) : (
        <PropagateLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
    </>
  )
}

export default DocumentListComponent
