import styled from 'styled-components'
import logoBackground from '../../assets/image/background-page-list.svg'

export const Section = styled.section`
  width: 100%;
  height: 100vh;
  padding: 4rem 0 0;
`

export const Content = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: url(${logoBackground});
  background-repeat: no-repeat;
  background-position: 100% 30%;
  background-size: 18%;
  z-index: -1;
`
export const ContentBox = styled.div`
  height: 100vh;
  width: 2%;
  position: fixed;
  background-color: #a6151c;
`

export const Container = styled.div`
  padding: 3rem 0px 2rem 6rem;
  z-index: 999;
`

export const DataTitle = styled.div`
  /* margin: 0 0 2rem; */
  margin: 0 0 1.5rem 0;
  color: #373737;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
  h2 {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 24px;
  }
  hr {
    height: 3.5px;
    border: none;
    border-radius: 3px;
    width: 7rem;
    background-color: #a6151c;
  }
  button {
    background-color: #a6151c;
    cursor: pointer;
    color: #fff;
    letter-spacing: 0.5px;
    border-radius: 6px;
    border: none;
    width: 8rem;
    height: 2.2rem;
    &:active {
      transform: scale(0.98);
    }
    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  }
`

export const TagName = styled.div`
  display: flex;
  width: 80%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 1rem 0rem;
  align-items: center;
  margin: 0;
  cursor: pointer;
  h3 {
    padding: 0 0 0 1rem;
  }
  svg {
    margin: 0 0 0 0.5rem;
    width: 0.8rem;
    height: 0.8rem;
  }
`

export const BoxRadius = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 8px;
  background-color: #373737;
  margin: 0 1rem 0 0;
`

export const ContentList = styled.div`
  margin: 0rem 2.5rem;
  h3 {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 0.9rem;
  }
`

export const DocumentsList = styled.ul`
  margin: 0.5rem 0rem 2rem 3rem;
`

export const DocumentsListItem = styled.li`
  /* margin: 1.5rem 0; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 0;
  border-bottom: 1px solid rgb(221, 221, 221);
  width: 75%;
  font-size: 0.8rem;
  div {
    display: flex;
    align-items: center;
  }
  button {
    cursor: pointer;
  }
`

export const ListData = styled.div`
  width: 100%;
  button {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    &:hover {
      background: #eee;
    }
    &:active {
      transform: scale(0.98);
    }
  }
  p {
    margin: 0px 0px 0px 0.2rem;
    /* max-width: 48%; */
    white-space: break-spaces;
    word-break: break-word;
    padding: 0 0.5rem 0 0;
  }
  strong {
    font-family: 'Museo500';
    margin: 0 0.2rem 0 0;
    white-space: break-spaces;
    word-wrap: break-word;
    &::first-letter {
      text-transform: capitalize;
    }
  }
`

export const ChangeData = styled.div`
  flex: 1 1 0%;
  border: 1px solid rgb(205 205 205);
  margin: 0 0 0 0.5rem;
  padding: 0.4rem 0.2rem;
  border-radius: 7px;
`

export const InputEdit = styled.input`
  border: none;
  padding: 0px 0.5rem;
  flex: 1;
  font-family: 'Museo300';
  font-size: 0.9rem;
  letter-spacing: 0.5px;
`

export const InfoData = styled.div`
  width: 100%;
  display: flex;
  display: flex;
  align-items: center;
  padding: 0 0.5rem 0 0.5rem;
`

export const BtnCancel = styled.button`
  margin: 0 0.2rem 0 0.5rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  svg path {
    fill: #a6151c;
  }
`

export const BtnConfirm = styled.button`
  margin: 0 1rem 0 0;
  padding: 0.2rem;
  svg {
    width: 1.2rem;
    height: 1rem;
  }
  svg path {
    fill: #9ca624;
  }
`

export const ClassificationDocList = styled.ul`
  margin: 2rem 0;
`

export const ClassificationDocListItem = styled.li`
  display: flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
  }
  strong {
    &::first-letter {
      text-transform: uppercase;
    }
  }
`

export const CheckBox = styled.button`
  width: 0.9rem;
  height: 0.9rem;
  /* border: 1px solid #373737; */
  border: none;
  position: relative;
  cursor: pointer;
  background-color: #a6151c;
  border-radius: 2px;
  flex-shrink: 0;
  margin: 0 0.6rem 0 0;
  /* img {
    position: absolute;
    bottom: 2%;
    right: -40%;
    width: 1rem;
  } */
  svg {
    width: 0.6rem;
    height: 0.9rem;
    z-index: 999;
  }
  svg path {
    fill: #fff;
  }
`

export const MarkDataWrapper = styled.div`
  position: absolute;
  left: 100%;
`

export const ComboBoxWrapper = styled.div`
  position: relative;
`

export const ComboBoxOptions = styled.ul`
  position: absolute;
  top: 30px;
  left: 0px;
  width: 380px;
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 0px 5px 1px rgba(0, 0, 0, 0.2);
  z-index: 999999999999;
`

export const ComboBoxOptionsItem = styled.li`
  padding: 0.8rem;
  border-bottom: 1px solid #ccc;
  display: flex;
  cursor: pointer;
  transform: background-color 0.2s ease-in;
  button {
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1.5px solid #ccc;
    margin: 0 0.5rem 0 0;
    &:active {
      background: red;
    }
  }
  &:first-child {
    padding: 0.5rem 0.8rem 0.8rem;
  }
  &:last-child {
    border-bottom: none;
    padding: 0.8rem 0.8rem 0.8rem;
  }
  &:hover {
    /* background-color: #eee;
    border-radius: 7px; */
  }
`

export const ComboBoxToggle = styled.button`
  svg {
    width: 2rem;
    height: 1.4rem;
  }
`

export const CopyData = styled.div`
  position: relative;
`

export const ConfirmCopyValue = styled.div`
  display: ${({ openCopyButton }) => (openCopyButton ? 'flex' : 'none')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  font-size: 0.9rem;
  z-index: ${({ visible }) => (visible ? 9 : -1)};
  right: 2rem;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: rgb(255, 255, 255);
  padding: 0 0.6rem 0 0.4rem;
  height: 2rem;
  width: 5.8rem;
  border-radius: 6px;
  align-items: center;
  font-family: Museo500sans;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
  transition: opacity 0.4s ease-in-out;
  justify-content: center;
  img {
    width: 1rem;
    margin: 0 0.4rem 0 0rem;
  }

  svg {
    font-size: 2rem;
  }
`

export const BtnCopy = styled.button`
  background-color: #6f6f6f;
  border: none;
  border-radius: 5px;
  padding: 0 0.4rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: background-color 0.05s ease-in;
  &:active {
    transform: scale(0.98);
  }
  &:hover {
    background-color: rgb(87 86 86);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`

export const BtnEdit = styled.button`
  border: none;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  padding: 0.2rem;
  svg {
    width: 1rem;
    height: 1.2rem;
  }
  svg path {
    fill: #42494e;
  }
  &:hover {
    background: #eee;
  }
`
