import { useContext, useEffect, useRef, useState } from 'react'
import * as S from './styles'
import { DocsContext } from '../../context/docs-context'
import { ReactComponent as ArrowDown } from '../../assets/image/arrow-down-review.svg'
import insertSpaceBetweenWords from '../../utils/utils'
import { ReactComponent as Docs } from '../../assets/image/document8.svg'

const DocumentRating = (props) => {
  const [getListDocTypes, setGetListDocTypes] = useState(false)
  const [docValidate, setDocValidate] = useState(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [inputFocused, setInputFocused] = useState(false)
  const [typeDocumentSelected, setTypeDocumentSelected] = useState(
    props.docTypes[0].name
  )
  const [validatedDocument, setValidatedDocument] = useState({})
  const [searchQuery, setSearchQuery] = useState('')
  const [clearTextButton, setClearTextButton] = useState(false)
  const documentListRef = useRef(null)
  const inputRef = useRef(null)
  const buttonRef = useRef(null)
  const [openSearch, setOpenSearch] = useState(false)
  const openModalConfirmRef = useRef(null)
  const {
    activeTag,
    dataDocsDataList,
    classificationListDocs,
    idFileDoc,
    showClassification,
    configData,
    validationDocument,
    startingLoading,
    showForm,
    handleTypeDocumentsOption,
    handlePreviewDocsReview,
    showListDocuments,
    setStartingLoading,
    showResumeDocToContract,
  } = useContext(DocsContext)

  useEffect(() => {
    const documentToValidated = configData?.documents.find(
      (item) => item?.documentId === idFileDoc
    )
    setValidatedDocument(documentToValidated)
  }, [idFileDoc, configData?.documents])

  useEffect(() => {
    const selectedItem = dataDocsDataList?.find((item) => item.id === idFileDoc)
    setDocValidate(selectedItem?.validado || false)
  }, [idFileDoc, dataDocsDataList])

  const handleConfirm = async (id) => {
    setOpenSearch(false)
    setSearchQuery('')
    setStartingLoading(true)

    handleTypeDocumentsOption(typeDocumentSelected, id)
    setOpenModalConfirm(false)
  }

  const handleClassificationDocs = (document) => {
    setSearchQuery('')
    setOpenSearch(false)
    if (validatedDocument?.classification?.label === document.label) {
      setGetListDocTypes(false)
      return
    }
    setTypeDocumentSelected(document)
    setOpenModalConfirm(true)
    setGetListDocTypes(false)
  }

  const handleVisibleList = () => {
    if (!validationDocument || inputFocused) {
      return
    }
    if (showForm) {
      return
    }
    if (!getListDocTypes) {
      setGetListDocTypes(true)
      setOpenSearch(true)
      setTimeout(() => inputRef.current?.focus(), 0)
    }
  }

  const handleClickOutside = (event) => {
    const isInsideDocumentList =
      documentListRef?.current?.contains(event.target) ?? false
    const isInsideInput = inputRef?.current?.contains(event.target) ?? false
    const isInsideButton = buttonRef?.current?.contains(event.target) ?? false

    if (isInsideDocumentList || isInsideInput || isInsideButton) {
      return
    } else {
      setGetListDocTypes(false)
      setOpenSearch(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside)
    return () => {
      document.removeEventListener('mouseup', handleClickOutside)
    }
  }, [])

  const handleArrowClick = () => {
    setGetListDocTypes(false)
    setOpenSearch(false)
  }

  const handleCancel = () => {
    setOpenSearch(false)
    setSearchQuery('')
    setOpenModalConfirm(false)
  }

  return (
    <>
      {openModalConfirm && <S.ModalOverlay></S.ModalOverlay>}
      <S.Section
        ref={documentListRef}
        showListDocuments={showListDocuments}
        showClassification={showClassification}
        validationDocument={validationDocument}
      >
        <S.ClassificationDoctype>
          <h2>{props.label}:</h2>
          <S.NavDocumentTypeList>
            <S.Classification>
              {validationDocument ? (
                <S.ButtonConfirmDoc
                  ref={documentListRef}
                  dataValidaded={activeTag?.dataValidated}
                  openSearch={openSearch}
                  validatedDocument={validatedDocument?.validated}
                  onClick={handleVisibleList}
                  disabled={validatedDocument?.validated}
                  showForm={showForm}
                  validationDocument={validationDocument}
                  clearTextButton={clearTextButton}
                >
                  {!openSearch && (
                    <p>
                      {insertSpaceBetweenWords(
                        validatedDocument?.classification?.label ||
                          'Não classificado'
                      )}
                    </p>
                  )}
                  <S.SearchDocuments openSearch={openSearch}>
                    <input
                      onFocus={() => setInputFocused(true)}
                      onBlur={() => setInputFocused(false)}
                      ref={inputRef}
                      type="text"
                      value={searchQuery}
                      onChange={(e) => {
                        setClearTextButton(true)
                        setSearchQuery(e.target.value)
                      }}
                      placeholder={
                        validatedDocument?.classification?.label ||
                        'Digite a classifição...'
                      }
                    />
                  </S.SearchDocuments>
                  {!showForm && validationDocument && (
                    <span ref={buttonRef} onClick={handleArrowClick}>
                      <ArrowDown
                        style={{
                          transform: getListDocTypes
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                        }}
                      />
                    </span>
                  )}
                </S.ButtonConfirmDoc>
              ) : (
                <S.NoClassification>Não classificado</S.NoClassification>
              )}
              {!showForm && (
                <S.ResumeDocs
                  showResumeDocToContract={showResumeDocToContract}
                  validationDocument={validationDocument}
                  onClick={() => {
                    handlePreviewDocsReview()
                  }}
                  title={'Listar Documentos'}
                >
                  <Docs />
                </S.ResumeDocs>
              )}
            </S.Classification>
            {openModalConfirm && (
              <S.ModalContainer ref={openModalConfirmRef}>
                <S.ModalContent
                  onClick={(e) => e.stopPropagation()}
                  startingLoading={startingLoading}
                >
                  <strong>Selecionado:</strong>
                  <h3>{typeDocumentSelected.label}</h3>
                  <button
                    onClick={() => handleConfirm(idFileDoc)}
                    style={{
                      backgroundColor: startingLoading ? '#b0b3b7' : '#9ca624',
                    }}
                  >
                    CONFIRMAR
                  </button>
                  <button
                    onClick={handleCancel}
                    style={{
                      backgroundColor: startingLoading ? '#b0b3b7' : '#a6151c',
                    }}
                  >
                    CANCELAR
                  </button>
                </S.ModalContent>
              </S.ModalContainer>
            )}
          </S.NavDocumentTypeList>
        </S.ClassificationDoctype>
      </S.Section>
    </>
  )
}

export default DocumentRating
