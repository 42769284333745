import React from 'react'
import styled, { keyframes } from 'styled-components'

const drawLine = keyframes`
  from {
    transform: scale(0) rotate(45deg);
  }
  to {
    transform: scale(1) rotate(45deg);
  } 
`

const drawLine2 = keyframes`
  from {
    transform: scale(0) rotate(-45deg);
  }
  to {
    transform: scale(1) rotate(-45deg);
  } 
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Line1 = styled.div`
  width: 24px;
  height: 3.5px;
  border-radius: 50px;
  background-color: #a6151c;
  transform-origin: top left;
  transform: rotate(45deg) scale(0);
  animation: ${drawLine} 0.1s ease-in-out forwards;
  animation-delay: 0s; 
`

const Line2 = styled.div`
  width: 24px;
  height: 3.5px;
  border-radius: 50px;
  background-color: #a6151c;
  transform-origin: bottom left;
  transform: rotate(-45deg) scale(0);
  animation: ${drawLine2} 0.1s ease-in-out forwards;
  animation-delay: 0.5s;
  position: relative;
  top: 16px;
  bottom: 0;
  right: 24px;
`

const DocumentNotValidate = () => {
  return (
    <Container>
        <>
          <Line1 />
          <Line2 />
        </>
    </Container>
  )
}

export default DocumentNotValidate
