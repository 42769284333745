import React from 'react'
import fetchWithAuth from './fetchWithAuth'

const FetchContext = React.createContext()

export const FetchInterceptor = ({ children }) => {
  return (
    <FetchContext.Provider value={fetchWithAuth}>
      {children}
    </FetchContext.Provider>
  )
}

export const useFetch = () => {
  return React.useContext(FetchContext)
}

export default FetchInterceptor
