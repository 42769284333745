import styled from 'styled-components'

export const Header = styled.header`
  height: 4rem;
  box-shadow: 0px 4px 5.7px -3px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  position: ${({openDocumentPreview}) => openDocumentPreview ? 'fixed' : 'relative'};
  z-index: 4;
`

export const BtnBack = styled.button`
  background-color: #a6151c;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  width: 2.5rem;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`

export const Logo = styled.span`
  width: 92%;
  display: flex;
  align-items: center;
  @media (min-width: 1000px) {
    width: 94%;
  }
`

export const BtnOff = styled.button`
  background-color: transparent;
  border: none;
  img {
    width: 1.2rem;
  }
`
