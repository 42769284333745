import styled from 'styled-components'
import Logo from '../../assets/image/logo-back.svg'

export const Section = styled.section`
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  top: 0;
  left: 0;
  display: ${({ validatedDocumentsModalOpen }) =>
    validatedDocumentsModalOpen ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  h2 {
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    font-weight: 700;
    color: #373737;
  }

  hr {
    height: 3.5px;
    background-color: ${({ validatedDocument }) =>
      validatedDocument ? '#9ca624' : '#a6151c'};
    border: none;
    border-radius: 2px;
    width: 100px;
    transition: background-color 0.2s ease-out;
    margin: 0.2rem 0;
  }
  img {
    width: 1.3rem;
    margin: 0 0 0 0.3rem;
  }
`
export const ContentBox = styled.div`
  background-color: #a6151c;
  width: 60px;
  border: 1px solid #a6151c;
  border-radius: 20px 0 0 20px;
  background-image: url(${Logo});
  background-repeat: no-repeat;
  background-position: 50% 95%;
  background-size: 60%;
  z-index: 1;
  @media (min-width: 700px) {
    width: 76px;
    background-position: 50% 95%;
  }
`
// export const CheckBox = styled.span`
//   width: 0.8rem;
//   height: 0.8rem;
//   border: 1px solid #373737;
//   position: relative;
//   border-radius: 3px;
//   margin: 0 0.4rem 0 0;
//   img {
//     position: absolute;
//     bottom: 2%;
//     right: -40%;
//     width: 1rem;
//   }
// `

export const CheckBox = styled.button`
  width: 0.9rem;
  height: 0.9rem;
  /* border: 1px solid #373737; */
  border: none;
  position: relative;
  cursor: pointer;
  background-color: #a6151c;
  border-radius: 2px;
  flex-shrink: 0;
  margin: 0 0.6rem 0 0;
  /* img {
    position: absolute;
    bottom: 2%;
    right: -40%;
    width: 1rem;
  } */
  svg {
    width: 0.6rem;
    height: 0.9rem;
    z-index: 999;
  }
  svg path {
    fill: #fff;
  }
`

export const Container = styled.div`
  background-color: rgb(244, 244, 244);
  border-radius: 20px;
  position: relative;
  width: 90%;
  min-height: 60vh;
  max-height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  @media (min-width: 700px) {
    width: 800px;
  }
`

export const Content = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2rem 1rem;
  @media (min-width: 700px) {
    padding: 2rem 2.5rem 2rem;;
    margin: 1rem;
  }
`

export const CloseModal = styled.button`
  display: flex;
  background: rgb(166, 21, 28);
  border: none;
  color: rgb(255, 255, 255);
  color: #f8aa62;
  border-radius: 50%;
  width: 3.4rem;
  height: 3.4rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  z-index: 99;
  position: absolute;
  right: -1rem;
  top: -1rem;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  svg {
    width: 0.9rem;
    opacity: 0.6;
  }
  &:active {
    transform: scale(0.98);
  }
  @media (max-width: 425px) {
    left: 92%;
  }
`

export const SendDocuments = styled.div`
  width: 100%;
  display: ${({ validationDocument }) => (!validationDocument ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
`

export const ContentButton = styled.button`
  display: flex;
  background: rgb(166, 21, 28);
  border: none;
  color: rgb(255, 255, 255);
  border-radius: 8px;
  margin: 0.5rem 0px 0px;
  padding: 0 1.4rem;
  height: 2.5rem;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  &:active {
    transform: scale(0.98);
  }
`

export const DocumentsList = styled.ul`
  width: 90%;
  height: auto;
  max-height: ${({validationDocument}) => !validationDocument ? '65vh' : '58vh'}; 
  overflow-y: auto;
  scroll-behavior: smooth;
  margin: 0.5rem 1rem 3rem;
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 8px;
    background: linear-gradient(
      0deg,
      rgba(244, 244, 244, 0.5) 0%,
      rgba(255, 250, 250, 0.5) 100%
    );
    background-color: #ccc;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a6151c;
    border-radius: 7px;
  }
  @media (min-width: 700px) {
    padding: 0 0 0 0.5rem;
  }
`

export const DocumentsListItem = styled.li`
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.05); */
  padding: 1rem 1rem 0rem;
  h3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: capitalize;
    font-family: 'Museo500sans';
    margin: 0.5rem 0.5rem 1rem;
    font-size: 0.9rem;
    div {
      background: #000;
      width: 0.3rem;
      height: 0.3rem;
      margin: 0 0.5rem 0;
      border-radius: 100px;
    }
  }
  &:last-child {
    border: none;
  }
`

export const ClassificationDocList = styled.ul`
  margin: 1rem 0px 0.5rem;
    padding: 0px 0rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ClassificationDocListItem = styled.li`
  width: 85%;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  p {
    margin: 0 0 0 0rem;
    display: flex;
    align-items: center;
    font-family: 'Museo500sans';
    font-size: 0.8rem;
  }
  &:last-child {
    border-bottom: none;
  }
`
