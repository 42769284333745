import React, { useContext } from 'react'
import * as S from './styles'
import { DocsContext } from '../../context/docs-context'
import { ReactComponent as Close } from '../../assets/image/close-modal.svg'

const ModalAllListDataDocuments = ({ additionalInformation }) => {
  const { openModalAllLists, handleOpenModalAllLists } = useContext(DocsContext)

  // Verifique se additionalInformation existe e tem dados
  if (
    !additionalInformation ||
    Object.keys(additionalInformation).length === 0
  ) {
    return null // ou um fallback JSX que faça sentido
  }

  const extractHeaders = (data) => {
    const headers = new Set()
    data.forEach((item) => {
      Object.keys(item).forEach((key) => headers.add(key))
    })
    return Array.from(headers)
  }

  const data = Object.values(additionalInformation).flat()
  const headers = extractHeaders(data)

  return (
    <S.Section openModalAllLists={openModalAllLists}>
      <S.Container>
        <S.ContentBox></S.ContentBox>
        <S.Content>
          <h2>INFORMAÇOES COMPLEMENTARES</h2>
          <hr />
          <S.DocumentsList>
            <table>
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header}>{header.toUpperCase()}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    {headers.map((header) => (
                      <td key={header}>{item[header] || ''}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </S.DocumentsList>
        </S.Content>
        <S.CloseModal onClick={handleOpenModalAllLists}>
          <Close />
        </S.CloseModal>
      </S.Container>
    </S.Section>
  )
}

export default ModalAllListDataDocuments
