import styled from 'styled-components'

export const Container = styled.div`
  height: calc(100vh - 16px);
  position: relative;
  display: ${({ previewBoxVisibility }) =>
    previewBoxVisibility ? 'flex' : 'none'};
  @media (max-width: 1022px) {
    height: 60vh;
  }
`

export const ImageCertified = styled.img`
  width: 5rem;
  position: absolute;
  top: 192px;
  left: 60%;
  -webkit-animation-name: pulse;
  animation-name: pulse;
  /* animation-delay:2.5s; */
  /* -webkit-animation-delay:2.5s; */
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  opacity: 0;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  @keyframes pulse {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0.5;
      transform-origin: 50% 50%;
      transform: rotate(-1deg) scale(5);
      transition: all 0.3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
    }
    100% {
      opacity: 1;
      transform: rotate(-4deg) scale(1);
    }
  }
`
export const BtnDelete = styled.div`
  position: absolute;
  z-index: 999;
  background: #a6151c;
  border: 1px solid #ccc;
  width: 2.5rem;
  height: 2.5rem;
  display: ${({ showDeleteButton }) => (showDeleteButton ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  top: 92%;
  left: 2%;
  svg {
    pointer-events: none;
    width: 1.5rem;
    height: 1.5rem;
    path {
      fill: #fff;
    }
  }
  @media (min-width: 1020px) {
    left: 3%;
    top: 90%;
    position: fixed;
  }
  &:active {
    scale: 0.95;
  }
`

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`

export const ModalContainer = styled.div`
  /* position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 20;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  background: #f4f4f4;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  width: 100%; */
  position: absolute;
  bottom: 0;
  left: 3rem;
  border: 1px solid #000;
  z-index: 20;
  background: rgb(244, 244, 244);
  box-shadow: rgba(0, 0, 0, 0.9) 0px 2px 4px;
  border-radius: 8px;
  width: 100%;
  display: flex;
`

export const ContentBox = styled.div`
  width: 20px;
  border-radius: 8px 0 0 8px;
  background-color: #a6151c;
`

export const ContentModal = styled.div`
  position: absolute;
  top: 72%;
  left: 8%;
  width: 450px;
`

export const ModalContent = styled.div`
  padding: 1.5rem 1.5rem 2rem 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  div {
    display: flex;
    margin: 1.5rem 0 0;
  }
  p {
    color: #a6151c;
    margin: 0.5rem 0 0;
  }
  strong {
    font-family: 'Museo500sans';
    font-size: 0.9rem;
  }
  h3 {
    text-transform: uppercase;
    color: rgb(166, 21, 28);
    font-family: Inter, sans-serif;
    margin: 0.4rem 0 1.2rem;
    font-weight: 800;
  }
  button {
    cursor: pointer;
    color: #fff;
    border: none;
    border-radius: 5px;
    height: 2.2rem;
    padding: 0 0.4rem;
    font-family: Inter, sans-serif;
    margin: 0 0.5rem 0 0;
    &:active {
      transform: 'scale(0.98)';
    }
  }
`

export const NumberDocs = styled.div`
  position: absolute;
  font-family: 'Museo500sans';
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  z-index: 10;
  right: 1.8rem;
  top: 5rem;
  display: flex;
`
