import styled from 'styled-components'

export const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
  text-align: center;
  @media (min-width: 1022px) {
    display: flex;
    flex-direction: row;
  }
`

export const Container = styled.div`
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  @media (min-width: 1022px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    span {
      margin: 1rem 0;
    }
  }
`

export const BtnClose = styled.button`
  background-color: transparent;
  border: 1px solid #a6151c;
  color: #a6151c;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
  padding: 0.8rem 2rem;
  margin: 2rem 0;
  border-radius: 8px;
  cursor: pointer;
  @media (min-width: 1022px) {
    margin: 1rem 0;
  }
`

export const Content = styled.div`
  svg {
    width: 150px;
    height: 150px;
  }
  hr {
    width: 300px;
    height: 0.5px;
    background-color: #878787;
    opacity: 0.6;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
    margin: 2rem 0 1rem;
  }
  @media (min-width: 1022px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    hr {
      width: 0.5px;
      height: 350px;
      margin: 0 4rem;
    }
  }
`

export const ErrorMessage = styled.div`
  margin: 1rem 0 0.5rem;
  display: flex;
  font-size: 0.9rem;
  font-family: 'Inter', sans-serif;
  flex-direction: column;
  font-weight: 300;
  strong {
    font-size: 0.9rem;
    color: #373737;
    text-transform: uppercase;
    span {
      display: block; /* Adiciona este display para permitir margin e padding */
      margin: 5px;
    }
  }
  @media (min-width: 1022px) {
    text-align: left;
    strong {
      font-size: 0.9rem;
    }
  }
`
