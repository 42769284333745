import styled from 'styled-components'

export const Section = styled.section`
  margin: ${({ validationDocument }) =>
    validationDocument ? '1.5rem 0 0rem' : '0.5rem 0 0.8rem'};
  width: 100%;
  /* margin: 2rem 0 0; */
  /* display: ${({ validationDocument }) =>
    validationDocument ? 'flex' : 'none'}; */
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem 0 0;
  h1 {
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: #373737;
    line-height: 22px;
    margin: 0 0.3rem 0 0;
  }
  div {
    display: flex;
  }
  hr {
    height: 4px;
    background-color: ${({
      showForm,
      dataValidaded,
      validationDocument,
      validatedDocument,
    }) => {
      if (showForm) {
        return dataValidaded ? '#9ca624' : '#a6151c'
      }
      return !validationDocument
        ? '#a6151c'
        : validatedDocument
        ? '#9ca624'
        : '#a6151c'
    }};
    border: none;
    border-radius: 2px;
    width: 60px;
    transition: background-color 0.2s ease-out;
  }
`

export const Title = styled.div`
  flex-direction: column;
  div {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0 0;
  }
`

export const Validation = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  /* justify-content: ${({ validationDocument }) =>
    validationDocument ? 'flex-start' : 'space-between'}; */
  position: relative;
`
export const CircleBox = styled.div`
  background: #e0e0e0;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  position: relative;
  margin: 0px 0px 0px 0.4rem;
`

export const ConfirmDocumentValidate = styled.div`
  position: relative;
  margin: 1rem 0 0;
  display: ${({ validationDocument }) =>
    validationDocument ? 'flex' : 'none'};
  span {
    position: absolute;
    top: ${({ validated }) => (validated ? '-2px' : '0px')};
    left: ${({ validated }) => (validated ? '10px' : '9px')};
  }
`

export const BtnCheckList = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 0;
  height: 2.3rem;
  width: 2.4rem;
  border-radius: 8px;
  border: 1px solid rgb(204, 204, 204);
  background-color: transparent;
  cursor: pointer;
  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
  }
  &:active {
    transform: scale(0.98);
  }
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
  svg path {
    fill: #878787;
  }
`
