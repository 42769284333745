import React, { useRef } from 'react'
import * as S from './styles'
import { ReactComponent as Close } from '../../assets/image/close-modal.svg'

const ModalDocumentsLint = ({ docToLint, handleOpenModalSubmit }) => {
  const modalContainerRef = useRef(null)

  const handleClose = () => {
    // console.log('Encaminhar para o lint')
  }
  // console.log(docToLint.item)
  return (
    <S.Section>
      <S.Container ref={modalContainerRef}>
        <S.ContentBox></S.ContentBox>
        <S.Content>
          <S.Wrapper>
            <h2>Documentos Selecionados para Revisão</h2>
            <hr />
            <S.DocumentsList> 
              {docToLint && docToLint.length > 0 ? (
                docToLint.map((doc, index) =>
                  doc?.item &&
                  Array.isArray(doc.item) &&
                  doc.item.length > 0 ? (
                    <S.DocumentsListItem key={doc.key}>
                      <S.Title>
                        <S.Box></S.Box>
                        <h3>{doc?.key}</h3>
                      </S.Title>
                      <S.ClassificationDocList>
                        {doc.item.map((document) => (
                          <S.ClassificationDocListItem key={document.keyJson}>
                            <strong>{document.labelJson}:</strong>{' '}
                            {document.valueDoc}
                          </S.ClassificationDocListItem>
                        ))}
                      </S.ClassificationDocList>
                    </S.DocumentsListItem>
                  ) : null
                )
              ) : (
                <p>Nenhum documento encontrado.</p> // Mensagem ou elemento que deseja renderizar quando não houver documentos
              )}
            </S.DocumentsList>
          </S.Wrapper>
          <S.SendDocuments>
            <S.ContentButton onClick={handleClose}>CONCLUIR</S.ContentButton>
          </S.SendDocuments>
        </S.Content>
        <S.CloseModal onClick={handleOpenModalSubmit}>
          <Close />
        </S.CloseModal>
      </S.Container>
    </S.Section>
  )
}

export default ModalDocumentsLint
