// função que gerencia token
import { PublicClientApplication } from '@azure/msal-browser'

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
  },
})

const getToken = async () => {
  await msalInstance.initialize()

  const account = msalInstance.getAllAccounts()[0]
  if (!account || !localStorage.getItem('token')) {
    throw new Error('No active account! Verify a user has been signed in.')
  }
  const timestamp = Math.floor(new Date().getTime())
  let tokenDefault = localStorage.getItem('token')
  if (
    !localStorage.getItem('tokenExp') ||
    !localStorage.getItem('token') ||
    (localStorage.getItem('tokenExp') &&
      parseInt(localStorage.getItem('tokenExp')) <= timestamp)
  ) {
    try {
      const response = await msalInstance.acquireTokenSilent({
        account,
        forceRefresh: true,
      })
      tokenDefault = response?.idToken
      localStorage.setItem('token', response?.idToken)
      localStorage.setItem(
        'tokenExp',
        response?.account?.idTokenClaims?.exp.toString()
      )
    } catch (error) {
      const response = await msalInstance.loginRedirect({
        account,
        forceRefresh: true,
      })
      return response
    }
  }
  return tokenDefault
}

export default getToken
