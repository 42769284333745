import styled from 'styled-components'

export const Section = styled.div`
  height: 2.2rem;
  margin: 0 0 0 0.8rem;
  border-radius: 8px;
  font-family: 'Museo300';
  color: #42494e;
  border: 0.5px solid #878787;
  width: 180px;
  cursor: pointer;
  position: relative;
  background-color: #fff;
  color: #5c5c5c;
  svg {
    width: 0.8rem;
    height: 0.8rem;
  }
  svg path {
    fill: #a6151c;
  }
`

export const BtnOpenSelect = styled.button`
  background-color: transparent;
  border: none;
  font-family: 'Museo300';
  font-size: 0.9rem;
  padding: 0 0.6rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: 2.2rem;
`

export const OptionList = styled.ul`
  display: ${({ openListLint }) => (openListLint ? 'flex' : 'none')};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 2.2rem;
  left: 0;
  z-index: 99999999999;
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding: 0.5rem 0;
  border: 0.5px solid #878787;
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  height: ${({ openListLint, searchQuery }) => {
    if (!openListLint) return '0'
    // // if (length <= 0 && searchQuery !== '') return '65px'
    // if (length <= 0 && searchQuery !== '') return '60px'
    // if (length >= 4) return '200px'
    // if (length === 3) return '170px'
    // if (length === 2) return '110px'
    // if (length === 1) return '60px'
    return 'auto'
  }};
  transition: height 0.3s ease-in;
  height: auto;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 20px;
    background: linear-gradient(
      0deg,
      rgba(244, 244, 244, 0.5) 0%,
      rgba(255, 250, 250, 0.5) 100%
    );
  }

  &::-webkit-scrollbar-thumb {
    background: #a6151c;
    border-radius: 20px;
  }
`

export const BtnSCloseSearch = styled.button`
  background-color: transparent;
  border: none;
  padding: 0 0.5rem 0 0;
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`

export const SearchDocuments = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 90%;
  display: ${({ openSearch }) => (openSearch ? 'flex' : 'none')};
  align-items: center;
  justify-content: flex-start;
  border: none;
  background-color: transparent;
  padding: 0 0.2rem;
  button {
    /* right: 3px; */
    left: 92%;
    padding: 0px;
    margin: 0px;
    background: transparent;
    border: none;
    top: 4px;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  input {
    width: 100%;
    display: flex;
    height: 2rem;
    color: #373737;
    border: none;
    align-items: center;
    font-size: 1rem;
    padding: 0 0.4rem;
    font-family: 'Museo300';
    background-color: transparent;
    &::placeholder {
      color: #373737;
      opacity: 0.4;
      font-size: 0.8rem;
      font-family: 'Museo300';
    }
    &:active {
      border: none;
    }
    &::first-letter {
      text-transform: uppercase;
    }
  }
`

export const OptionListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0.6rem;
  margin: 0 0.5rem;
  border-bottom: 0.5px solid rgb(221, 221, 221);
  &:hover {
    background: #a6151c;
    color: #fff;
    border-radius: 8px;
  }
  &:last-child {
    border-bottom: none;
  }
`

export const NoClassificationText = styled.li`
  color: #999;
  font-style: italic;
  text-align: center;
  padding: 0 0.5rem;
`
