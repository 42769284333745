import React from 'react'
import * as S from './styles'
import { ReactComponent as Logo } from '../../assets/image/logo-multiplan-big.svg'
import { useContext } from 'react'
import { DocsContext } from '../../context/docs-context'

const ErrorComponent = () => {
  const {configId} = useContext(DocsContext)

  const handleClose = () => {
    document.cookie = `analyzer_view_${configId}=close;domain=.multiplan.com.br`
  }
  return (
    <S.Section>
      <S.Content>
        <Logo />
        <hr />
      </S.Content>
      <S.Container>
        <S.ErrorMessage>
          <p>
            <strong>Página não encontrada</strong>
          </p>
          <p>ou acesso negado.</p>
        </S.ErrorMessage>
        <span>Verifique as informações e tente novamente.</span>
        <S.BtnClose onClick={handleClose}>
          FECHAR
        </S.BtnClose>
      </S.Container>
    </S.Section>
  )
}

export default ErrorComponent
