import React, { useState } from 'react'
import * as S from './styles'
import FadeLoader from 'react-spinners/FadeLoader'

const override = {
  display: 'block',
  margin: '2rem 0',
}

const LoaderInitial = () => {
  //eslint-disable-next-line no-unused-vars
  let [loading, setLoading] = useState(true)

  //eslint-disable-next-line no-unused-vars
  let [color, setColor] = useState('#ccc')

  return (
    <S.Section>
      <FadeLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={200}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </S.Section>
  )
}

export default LoaderInitial
