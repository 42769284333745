import { createContext, useEffect, useState } from 'react'
import { useFetch } from '../interceptor/fetchInterceptor'
import { defaultColors } from '../constant/documentstype'
import { toast } from 'react-toastify'
import { groupDocuments } from '../utils/utils'
import { getAppInsights } from '../insights/app-insights'

export const DocsContext = createContext()

const { Provider } = DocsContext

export const DocsProvider = (props) => {
  const fetchWithAuth = useFetch()
  const [collection, setCollection] = useState([])
  const fileId = collection[0]
  const [configId, setConfigId] = useState('')
  const [isLoadingForm, setIsLoadingForm] = useState(false)
  const [isFormDataEmpty, setIsFormDataEmpty] = useState(true)
  const [validatedDocument, setValidatedDocument] = useState(false)
  const [combinededDocuments, setCombinededDocuments] = useState([])
  const [dataDocumentToLint, setDataDocumentToLint] = useState([])
  const [idFileDoc, setIdFileDoc] = useState('')
  const [totalValidateDocs, setTotalValidateDocs] = useState(0)
  const [allDocsValidated, setAllDocsValidated] = useState(false)
  const [openModalSendDocs, setOpenModalSendDocs] = useState(false)
  const [typeDocuments, setTypeDocuments] = useState('')
  const [contractPartsSelected, setContractPartsSelected] = useState([])
  const [filteredPartsContract, setFilteredPartsContract] = useState()
  const [filterSelectedColor, setFilterselectedcolor] = useState()
  const [analyzerDataList, setAnalyzerDataList] = useState([])
  const [confirmEditValuesDocLInt, setConfirmEditValuesDocLInt] = useState([])
  const [configData, setConfigData] = useState(null)
  const [documentListData, setDocumentListData] = useState([])
  const [classificationListDocs, setClassificationListDocs] = useState([])
  const [contractPartsDoc, setContractPartsDoc] = useState([])
  const [docSelected, setDocSelected] = useState()
  const [progressBar, setProgressBar] = useState(true)
  const [previewSize, setPreviewSize] = useState([])
  const [boxToken, setBoxToken] = useState('')
  const [boxTokenTemp, setBoxTokenTemp] = useState('')
  const [boxTokenExp, setBoxTokenExp] = useState()
  const [timer, setTimer] = useState(0)
  const [colorsByDocumentId, setColorsByDocumentId] = useState({})
  const [tagsWithColors, setTagsWithColors] = useState([])
  const [documentsPartArray, setDocumentsPartArray] = useState([])
  const [previewBoxVisibility, setPreviewBoxVisibility] = useState()
  const [showClassification, setShowClassification] = useState()
  const [showContractPart, setShowContractPart] = useState()
  const [allDataValidated, setAllDataValidated] = useState([])
  const [validationDocument, setValidationDocument] = useState()
  const [showLabelText, setShowLabelText] = useState()
  const [showLabelTag, setShowLabelTag] = useState(false)
  const [labelTag, setLabelTag] = useState('')
  const [showListDocuments, setShowListDocuments] = useState()
  const [openModalAllLists, setOpenModalAllLists] = useState(false)
  const [showError, setShowError] = useState()
  const [canDownloaded, setCanDownloaded] = useState()
  const [canPrinted, setCanPrinted] = useState()
  const [openDocumentPreview, setOpenDocumentPreview] = useState(false)
  const [errorDocBox, setErrorDocBox] = useState([])
  const [submitDocList, setSubmitDocList] = useState([])
  const [activeTag, setActiveTag] = useState([])
  const [showForm, setShowForm] = useState(undefined)
  const [canAcesseDocPreview, setCanAcesseDocPreview] = useState(false)
  const [partSelected, setPartSelected] = useState({})
  const [validatedDocumentsModalOpen, setValidatedDocumentsModalOpen] =
    useState()
  const [tagRequiredFile, setTagRequiredFile] = useState()
  const [startingLoading, setStartingLoading] = useState(false)
  const [showDeleteButton, setShowDeleteButton] = useState()
  const [showResumeDocToContract, setShowResumeDocToContract] = useState()
  const [resumeTags, setResumeTags] = useState([])
  const [partFormListState, setPartFormListState] = useState([])

  const appInsights = getAppInsights()

  const handleOpenModalValidatedDocuments = () => {
    setValidatedDocumentsModalOpen(!validatedDocumentsModalOpen)
  }

  useEffect(() => {
    const updatedDocuments = configData?.documents?.map((doc) => {
      const { suggested, ...rest } = doc
      return rest
    })

    setConfigData((prevConfigData) => ({
      ...prevConfigData,
      documents: updatedDocuments,
    }))
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(window.location.search)
      const configId = queryParams.get('id')
      setConfigId(configId)
      // console.log('Config ID:', configId)

      if (configId) {
        try {
          // console.log('Iniciando requisição para obter configuração...')
          const response = await fetchWithAuth(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/configIds/?id=${configId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )

          if (!response.ok) {
            setShowError('Página não encontrada ou acesso não autorizado.')
            throw new Error(`Erro na resposta: ${response.statusText}`)
          }

          const data = await response.json()
          // console.log('Dados recebidos da configuração:', data)
          const index = tagsWithColors?.length % defaultColors.length

          const tagLabelFromData = {
            id: tagsWithColors?.length,
            color: defaultColors[index].color,
            part: data?.labelTag,
            numberTag: null,
          }
          //"1575070820683","1602139173017"
          const idDocumentBox = data.documents.map((item) => item.documentId)
          // console.log('Documentos recebidos:', idDocumentBox)
          // console.log('documentListData', documentListData)

          setConfigData(data)
          const firstItem =
            documentsPartArray?.documentId?.length > 0
              ? documentsPartArray[0]
              : null
          setShowForm(data.showForm)
          // console.log('configData', configData)
          const grouped = {}
          // const formTagdData = configData?.documents.map((doc) => {
          //   return { part: doc.part, numberTag: doc.numberTag ?? 1, documentId: doc.documentId};
          // })

          setCollection(idDocumentBox)
          setProgressBar(data.showProgressBar)
          setShowContractPart(data.showContractPart)
          setShowClassification(data.showClassification)
          setValidationDocument(data.showValidationButton)
          setTagRequiredFile(data.tagRequired)
          setShowLabelText(data.showLabelText)
          setBoxToken(data.boxToken)
          setShowListDocuments(data.showListDocuments)
          setBoxTokenExp(data.boxTokenExp)
          setClassificationListDocs(data.classifications)
          setContractPartsDoc(data.tags)
          setPreviewSize([80, 20])
          setDocumentListData(data.documents)
          setPreviewBoxVisibility(data.showPreviewDoc)
          setShowLabelTag(data.showLabelTag)
          setCanDownloaded(data.showCanDownload)
          setCanPrinted(data.showCanPrint)
          setLabelTag(tagLabelFromData)
          setResumeTags(data.resumeTags)
          setShowDeleteButton(data.showDeleteButton)
          setShowResumeDocToContract(data.showResumeDocToContract)
          if (idDocumentBox.length > 0 && !data.showForm) {
            console.log('chega aqui?')
            setIdFileDoc(idDocumentBox[0])
            // console.log('Documento inicial selecionado:', idDocumentBox[0])

            if (idFileDoc) {
              const selectedItem = analyzerDataList?.find(
                (item) => item?.retorno.idDocBox === idFileDoc
              )
              setDocSelected(selectedItem)
              // console.log('Documento selecionado:', selectedItem)
            }
          }
          // console.log("data classification:", data.documents)
          // setDataDocumentToLint(data.documents.map((item) => ({
          //   part: item.part,
          //   numberTag: item.numberTag ?? 1,
          //   data: {}
          // })));
          setDataDocumentToLint(
            Array.from(
              new Map(
                data.documents.map((item) => [
                  `${item.part}-${item.numberTag ?? 1}`,
                  { part: item.part, numberTag: item.numberTag ?? 1, data: {} },
                ])
              ).values()
            )
          )

          startInterval()

          const fetchAnalyzerData = data.documents.map(({ documentId }) =>
            fetchWithAuth(
              `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/documentDetails?idDocBox=${documentId}`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
              .then((response) => {
                if (!response.ok) {
                  throw new Error(
                    `Erro na resposta do analisador: ${response.statusText}`
                  )
                }
                return response.json()
              })
              .then((data) => {
                /* console.log(
                  'Dados do analisador recebidos para documento',
                  documentId,
                  ':',
                  data
                )*/
                return data
              })
              .catch((error) => console.error('Erro ao buscar dados:', error))
          )

          const analyzerDataResults = (
            await Promise.allSettled(fetchAnalyzerData)
          ).map((item) => {
            if (item.status === 'fulfilled') {
              const result = item.value
              return result
            } else {
              console.error(`Erro ao buscar analyzer data:`, item.reason)
              return { retorno: { dados: {} } } // Retorna o objeto padrão também no caso de erro
            }
          })
          /* console.log(
            'Resultados dos dados do analisador:',
            analyzerDataResults
          ) */
          setAnalyzerDataList(analyzerDataResults)
        } catch (error) {
          console.error('Erro ao buscar dados:', error)
        }
      }
    }

    fetchData()
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [fetchWithAuth])

  useEffect(() => {
    // console.log('Mantendo configId atualizado:', configId)
    _keepConfigIdUp(configId)
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [timer])

  const startInterval = () => {
    // console.log('Iniciando intervalo para atualizar timer')
    setInterval(() => {
      setTimer(new Date().getTime())
      // console.log('Timer atualizado:', new Date().getTime())
    }, 1000 * 60 * 3) // 3 minutos
    // }, 10000); // 10 segundos - debug
  }

  const _keepConfigIdUp = (configId) => {
    // console.log('Atualizando configId no servidor:', configId)
    const refreshBoxToken =
      boxTokenExp == null || boxTokenExp <= new Date().getTime() ? true : false
    const body = { refreshBoxToken: refreshBoxToken }
    fetchWithAuth(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/refreshConfig/?id=${configId}`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
      }
    )
      .then((data) => {
        return data.json()
      })
      .then((data) => {
        // console.log('Dados recebidos após atualização do configId:', data)
        if (refreshBoxToken) {
          setBoxTokenTemp(data.boxToken)
          setBoxTokenExp(data.boxTokenExp)
        }
      })
      .catch((error) => console.error('Erro ao atualizar configId:', error))
  }

  const checkToken = () => {
    // console.log('checkToken chamado, boxTokenTemp:', boxTokenTemp)
    if (!!boxTokenTemp) {
      setBoxToken(null)
      setTimeout(() => {
        setBoxToken(boxTokenTemp)
        // console.log('Token atualizado para:', boxTokenTemp)
      }, 50)
      setBoxTokenTemp(null)
    }
  }

  useEffect(() => {
    const formTagdData = configData?.documents?.reduce((acc, doc) => {
      const numberTag = doc.numberTag || 1

      // Verifica se `doc.part` é válido (não `null`, `undefined` ou string vazia)
      if (doc.part) {
        const existingGroup = acc.find(
          (group) => group.part === doc.part && group.numberTag === numberTag
        )

        if (existingGroup) {
          existingGroup.documentId.push(doc.documentId)
        } else {
          acc.push({
            part: doc.part,
            numberTag: numberTag,
            documentId: [doc.documentId],
            dataValidated: false,
          })
        }
      }

      return acc
    }, [])

    // console.log('FOOOOOOOOORRRRRMMMMMM', formTagdData) // Exibe o array final

    if (formTagdData) {
      setPartFormListState(formTagdData)
    }
  }, [configData])

  useEffect(() => {
    const fetchDataForm = async () => {
      setIsLoadingForm(true)
      if (configData?.documents) {
        const finalDocumentsArray = groupDocuments(
          configData.documents,
          tagsWithColors
        )

        const groupedDocuments = finalDocumentsArray.reduce((acc, doc) => {
          const key = `${doc.part}-${doc.numberTag}`
          if (!acc[key]) {
            acc[key] = {
              id: Object.keys(acc).length + 1,
              part: doc.part,
              numberTag: doc.numberTag,
              documents: [],
              tagAdd: doc.tagAdd,
            }
          }

          doc.documentId?.forEach((id) => {
            const docConfigData = configData.documents.find(
              (item) => item.documentId === id
            )
            const docAnalyzerData = analyzerDataList?.find(
              (item) => item?.retorno && item.retorno.idDocBox === id
            )

            if (docConfigData || docAnalyzerData) {
              acc[key].documents.push({
                documentId: id,
                documentData: {
                  configData: docConfigData || {},
                  analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
                },
              })
            }
          })

          return acc
        }, {})

        const otherDocuments = configData.documents.reduce((acc, doc) => {
          const docAnalyzerData = analyzerDataList?.find(
            (item) => item?.retorno && item.retorno.idDocBox === doc.documentId
          )

          acc.push({
            documentId: doc.documentId,
            documentData: {
              configData: doc || {},
              analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
            },
          })

          return acc
        }, [])

        if (otherDocuments.length > 0) {
          groupedDocuments['Outros'] = {
            id: Object.keys(groupedDocuments).length + 1,
            part: 'Outros',
            numberTag: null,
            documents: otherDocuments,
          }
        }

        const finalArray = Object.values(groupedDocuments)

        if (finalArray.length > 0) {
          const newCombinedDocuments = finalArray.map((doc) => {
            const dadosList =
              doc.documents.flatMap((item) => {
                const dados = item.documentData.analyzerData.dados || {}
                const label =
                  item.documentData.configData.classification?.label ||
                  'Não classificado'

                return Object.entries(dados).map(([key, value]) => ({
                  key,
                  label,
                  value: value || 'Não cadastrado',
                }))
              }) || []

            const dadosSeparados = dadosList
              .filter(({ key }) => !/hidden/i.test(key))
              .map(({ key, value, label }) => ({
                key,
                label,
                value,
              }))

            return {
              part: doc.part,
              numberTag: doc.numberTag,
              dados: dadosSeparados,
            }
          })
          const hasData = newCombinedDocuments.some(
            (item) => item.dados.length > 0
          )

          if (hasData) {
            setCombinededDocuments(newCombinedDocuments)
            setIsLoadingForm(false)
          }
        }
      }
    }
    fetchDataForm()
  }, [configData, tagsWithColors, showForm, analyzerDataList])

  /**
   *
   * aqui acaba os useeffect de requisicao
   */

  useEffect(() => {
    if (showForm === true) {
      const teste = partFormListState
        .filter((item) => item.part)
        .sort((a, b) => a?.part.localeCompare(b?.part))

      const firstItems = teste[0]

      console.log('firstItems', firstItems)

      if (partFormListState && firstItems) {
        console.log(`chamou aqui?`)
        setActiveTag(firstItems)
        setCollection(firstItems.documentId)
        setIdFileDoc(firstItems.documentId[0])
        if (activeTag) {
          // setIsLoading(false)
        }
        // setIsLoading(false)
      }
      // console.log({activeTag})
      // setCollection(firstItem);
    }
  }, [showForm])

  const handleDocumentsPartArray = (documents) => {
    // console.log('handleDocumentsPartArray chamado com documentos:', documents)
    setDocumentsPartArray(documents)
  }

  const handleTagSelected = (tag) => {
    // console.log('nao ta batendo?', tag)
    setPartSelected(tag)
  }

  const setDocumentColor = (idFileDoc, document, color) => {
    // console.log('setDocumentColor chamado com:', { idFileDoc, document, color })
    setColorsByDocumentId((prevColors) => ({
      ...prevColors,
      [idFileDoc]: {
        ...(prevColors[idFileDoc] || {}),
        [document]: color,
      },
    }))
  }

  const generateRandomColor = () => {
    // console.log('generateRandomColor chamado')
    const minColorValue = 0 // Valor mínimo para os componentes RGB
    const maxColorValue = 180 // Limita o máximo para evitar tons muito claros
    const randomColor = `rgb(${[0, 1, 2]
      .map(() =>
        Math.floor(
          Math.random() * (maxColorValue - minColorValue) + minColorValue
        )
      )
      .join(', ')})`
    // console.log('Cor gerada:', randomColor)
    return randomColor
  }

  const getDocumentColor = (idFileDoc, document) => {
    return colorsByDocumentId[idFileDoc]?.[document] || generateRandomColor()
  }

  const handleActiveTag = (item) => {
    console.log(`handleactive, item`, item)
    // console.log('qual alldatavalidated', allDataValidated)
    setActiveTag(item)
    console.log('aqui ta dentro de activetag', item)
    setCollection(item?.documentId)
    setIdFileDoc(item?.documentId[0])
  }

  const handleUpdateActiveTag = (item) => {
    // console.log(`activetagupdate`, item)
    setActiveTag(item)
  }

  useEffect(() => {
    // console.log(activeTag)
  }, [activeTag])

  const handleQttValidation = () => {
    // console.log('handleQttValidation chamado')
    const validatedCount = configData.documents.filter(
      (doc) => doc.validated === true
    ).length
    // console.log('Quantidade de documentos validados:', validatedCount)
    setTotalValidateDocs(validatedCount)
  }

  const handleSelectedDocument = (item) => {
    //  console.log('handleSelectedDocument chamado com:', item)
    setDocSelected(item)
  }

  const handleOpenModalSend = () => {
    //  console.log('handleOpenModalSend chamado')
    setOpenModalSendDocs(!openModalSendDocs)
  }
  const handleErrorDocBox = (url) => {
    //  console.log('handleErrorDocBox chamado com URL:', url)
    const regex = /idDocBox=([^&]+)/
    const match = url.match(regex)

    if (match) {
      const idDocBox = match[1]
      //   console.log('ID Doc Box extraído:', idDocBox)

      setErrorDocBox((prevErrors) => {
        if (!prevErrors.includes(idDocBox)) {
          /* console.log(
            'Erro não encontrado anteriormente, adicionando:',
            idDocBox
          ) */
          return [...prevErrors, idDocBox]
        }
        return prevErrors
      })
    }
  }

  const handleTypeDocumentsOption = async (value, id) => {
    let updatedDocument = null
    document.body.style.cursor = 'wait'
    setStartingLoading(true)

    const updatedDocuments = configData.documents.map((doc) => {
      if (doc.documentId === id) {
        updatedDocument = {
          ...doc,
          classification: value,
        }
        return updatedDocument
      }
      return doc
    })

    let idAnalyzer = null
    analyzerDataList?.forEach((analyzerDoc) => {
      if (analyzerDoc?.retorno.idDocBox === id) {
        idAnalyzer = analyzerDoc.retorno.idAnalyzer
      }
    })

    const newAnalyzerDataListFiltered = analyzerDataList?.filter(
      (item) => item?.retorno.idDocBox !== id
    )

    setAnalyzerDataList(newAnalyzerDataListFiltered)

    const payload = {
      idParams: configId,
      classificationDocument: updatedDocument,
      idAnalyzer,
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/classifyDocument`,
        requestOptions
      )
      const result = await response.json()

      if (!result?.retorno?.idAnalyzer) {
        let newAnalyzerDataListFiltered = []
        analyzerDataList?.forEach((item) => {
          if (item?.retorno?.idAnalyzer === idAnalyzer) {
            if (item && item.retorno) {
              item.retorno.dados = null
              item.retorno.lista = null
            }
          }
          newAnalyzerDataListFiltered.push(item)
        })

        setAnalyzerDataList(newAnalyzerDataListFiltered)
      } else {
        let newAnalyzerDataListFiltered = analyzerDataList?.filter(
          (item) => item?.retorno?.idAnalyzer !== result?.retorno?.idAnalyzer
        )
        newAnalyzerDataListFiltered.push(result)
        setAnalyzerDataList(newAnalyzerDataListFiltered)
      }

      setConfigData((prevData) => ({
        ...prevData,
        documents: updatedDocuments,
      }))

      setTypeDocuments(value)
    } catch (error) {
      console.error('Erro na classificação do documento:', error)
      toast.error(`Erro na classificação do documento`, {
        theme: 'light',
      })
    } finally {
      setStartingLoading(false)
      document.body.style.cursor = 'default'
    }
  }

  const handlePartForm = (tag) => {
    setPartFormListState(tag)
    // console.log(`partFormListState`, partFormListState)
  }

  // useEffect(() => {
  //   console.log(`USEEFFECT partFormListState:`, partFormListState)
  // }, [partFormListState])

  // useEffect(() => {
  //   console.log(`USEEFFECT activetag:`, activeTag)
  // }, [partFormListState])

  const handleClassificationDocs = (
    option,
    itemKeySelect,
    idSelected,
    value
  ) => {
    const findIndexMainKey = submitDocList.find(
      (item) => item.key === itemKeySelect
    )
    // console.log('Item encontrado para itemKeySelect:', findIndexMainKey)

    if (findIndexMainKey) {
      setSubmitDocList((prevList) => {
        // console.log('Atualizando submitDocList, lista anterior:', prevList)
        const updatedList = prevList.map((prev) => {
          if (prev.key === itemKeySelect) {
            const existingItemIndex = prev.item.findIndex(
              (item) => item.id === idSelected
            )

            if (existingItemIndex !== -1) {
              const updatedItems = prev.item.map((item, index) => {
                if (index === existingItemIndex) {
                  return {
                    ...item,
                    id: idSelected,
                    keyJson: option.key,
                    labelJson: option.label,
                    valueDoc: value,
                  }
                }
                return item
              })

              return {
                key: prev.key,
                item: updatedItems,
              }
            } else {
              return {
                key: prev.key,
                item: [
                  ...(Array.isArray(prev.item) ? prev.item : []),
                  {
                    id: idSelected,
                    keyJson: option.key,
                    labelJson: option.label,
                    valueDoc: value,
                  },
                ],
              }
            }
          }
          return prev
        })

        return updatedList
      })
    } else {
      const keyDocSubmit = {
        key: itemKeySelect,
        item: [
          {
            id: idSelected,
            keyJson: option.key,
            labelJson: option.label,
            valueDoc: value,
          },
        ],
      }
      //  console.log('Novo item criado para submitDocList:', keyDocSubmit)

      setSubmitDocList((prevList) => [...prevList, keyDocSubmit])
    }
  }

  useEffect(() => {
    //  console.log('submitDocList atualizado:', submitDocList)
  }, [submitDocList])

  const handleValidateDocument = () => {}

  useEffect(() => {
    const totalValidated = configData?.documents?.reduce((acc, currentItem) => {
      if (currentItem?.validated) {
        return acc + 1
      }
      return acc
    }, 0)

    setTotalValidateDocs(totalValidated)
    // console.log(totalValidated)
  }, [configData])

  const handleAllDocsValidatesValue = (value) => {
    value === true ? setAllDocsValidated(true) : setAllDocsValidated(false)
  }

  const handleContractParts = (part) => {
    //  console.log('handleContractParts chamado com:', part)
    if (contractPartsSelected.length >= 1) {
      const hasSameIdDocument = contractPartsSelected?.map((group) => {
        const filteredItems = group.items?.filter(
          (item) => item.idDocument !== part.idDocument
        )
        return { ...group, items: filteredItems }
      })

      setContractPartsSelected(hasSameIdDocument)
    }

    setContractPartsSelected((prev) => {
      const existingPart = prev.find((item) => item.name === part.name)
      if (existingPart) {
        const updatedParts = prev.map((item) =>
          item.name === part.name
            ? { ...item, items: [...item.items, part] }
            : item
        )
        return updatedParts
      }
      return [
        ...prev,
        { id: part.id, name: part.name, color: part.color, items: [part] },
      ]
    })
  }

  const handleAddFilter = (filter) => {}

  useEffect(() => {
    const filteredArray = contractPartsSelected.filter(
      (part) => part.items.length >= 1
    )
    if (filteredArray) {
      setFilteredPartsContract(filteredArray)
    } else {
    }
  }, [contractPartsSelected])

  const handleFilter = async (tag) => {
    if (!tag || !tag.documentId || tag.documentId.length <= 0) {
      handleClearFilter()
      setFilterselectedcolor()
      return
    }
    // Verifica se 'tag' existe antes de continuar
    if (tag) {
      const updatedCollection = tag.documentId
      setCollection(updatedCollection)
      setFilterselectedcolor(tag)
      console.log('talvez aqui?')
      setIdFileDoc(tag.documentId[0])
    } else {
      setFilterselectedcolor()
    }
  }

  const updatedFilterSelected = (tag) => {
    //  console.log('updatedFilterSelected chamado com tag:', tag)
    let selectedTag

    if (filterSelectedColor?.part === 'Pendentes') {
      selectedTag = tag.find((item) => item.part === 'Pendentes')
    } else if (filterSelectedColor?.part === 'Validados') {
      selectedTag = tag.find((item) => item.part === 'Validados')
    } else if (filterSelectedColor?.part === 'Não Cadastrados') {
      selectedTag = tag.find((item) => item.part === 'Não Cadastrados')
    }

    if (selectedTag && selectedTag.documentId.length === 0) {
      handleClearFilter()
      return
    }

    if (selectedTag) {
      setCollection()
      const updatedCollection = selectedTag?.documentId
      setCollection(updatedCollection)
      setFilterselectedcolor(selectedTag)
      console.log('oi gente')
      setIdFileDoc(selectedTag?.documentId[0])
    } else {
      // console.log('é isso aqui ent?')
      setFilterselectedcolor()
      handleClearFilter()
    }
  }

  const handleClearFilter = () => {
    //  console.log('handleClearFilter chamado')
    const idCollectionInitial = configData?.documents.map(
      (item) => item.documentId
    )
    setCollection(idCollectionInitial)
    setFilterselectedcolor()
  }

  const handleAnalyzerData = (update) => {
    setAnalyzerDataList(update)
  }

  const handleConfigData = (updatedDocuments) => {
    // console.log(
    //   'handleConfigData chamado com updatedDocuments:',
    //   updatedDocuments
    // )
    setConfigData((prevConfigData) => ({
      ...prevConfigData,
      documents: updatedDocuments,
    }))
  }

  const sendNewDocument = async (document) => {
    // console.log('como é o documento que esta enviando no payload: ', document)
    const payload = {
      idParams: configId,
      document: document,
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }

    return fetchWithAuth(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/validateDocument`,
      requestOptions
    )
  }

  const handleOpenModalAllLists = () => {
    setOpenModalAllLists(!openModalAllLists)
  }

  const handlePreviewDocsReview = () => {
    setOpenDocumentPreview(!openDocumentPreview)
  }

  //retorna os ids na configData que tem na collection atual
  const filterDocumentsByCollection = (configData, collection) => {
    return configData?.documents?.filter((item) =>
      collection.includes(item.documentId)
    )
  }

  //exclui um documento pelo documentId
  const deleteDocumentFromConfig = (configData, idFileDoc) => {
    return configData?.documents?.filter((doc) => doc.documentId !== idFileDoc)
  }

  //atualiza a lista de documentos do analyzer
  const updateDocumentListData = (documentListData, idFileDoc) => {
    return documentListData?.filter((doc) => idFileDoc !== doc.documentId)
  }

  // atualiza a collection removendo o documentId
  const updateCollection = (collection, idFileDoc) => {
    return collection.filter((doc) => idFileDoc !== doc)
  }

  //calcula o próximo idFileDoc peloo índice(nao funciona)
  const calculateNextIdFileDoc = (newCollection, nextIndex) => {
    if (nextIndex < newCollection.length) {
      return newCollection[nextIndex]
    } else if (nextIndex - 1 >= 0) {
      return newCollection[nextIndex - 1]
    }
    return null
  }

  const handleCollection = async () => {
    const configDataFilter = filterDocumentsByCollection(configData, collection)

    if (configDataFilter) {
      const documentToDeleteIndex = configDataFilter?.findIndex(
        (item) => item.documentId === idFileDoc
      )

      const documentToDelete = configData?.documents.find(
        (item) => item.documentId === idFileDoc
      )

      const documentToDeleteFalse = {
        documentId: '165162334q4456322487013',
        part: 'Fiador',
        validated: false,
      }

      const newCollection = updateCollection(collection, idFileDoc)

      const payload = {
        idParams: configId,
        document: documentToDelete,
      }

      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      }

      document.body.style.cursor = 'wait'

      try {
        const response = await fetchWithAuth(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/removeDocument`,
          requestOptions
        )

        if (!response.ok) {
          throw new Error('Erro ao tentar remover o documento no servidor.')
        }

        const updatedDocuments = deleteDocumentFromConfig(configData, idFileDoc)

        setConfigData((prevConfigData) => ({
          ...prevConfigData,
          documents: updatedDocuments,
        }))

        const updatedDocumentListData = updateDocumentListData(
          documentListData,
          idFileDoc
        )
        setDocumentListData(updatedDocumentListData)

        setCollection(newCollection)
        if (newCollection.length > 0) {
          console.log('mds quanto lugar')
          setIdFileDoc(newCollection[0])
        } else {
          const ids = updatedDocuments?.map((item) => item.documentId)
          setCollection(ids)
          console.log('pode ser aqui?')
          setIdFileDoc(ids[0] || null)
          setFilterselectedcolor()
        }
        // alert('Documento removido com sucesso!');
        toast.success(`Documento removido com sucesso!`, {
          theme: 'light',
        })
      } catch (error) {
        console.error('Erro na requisição:', error)
        return toast.error(`Erro ao deletar o documento, tente novamente!`, {
          theme: 'light',
        })
      } finally {
        document.body.style.cursor = 'default'
      }
    }
  }

  // const handleClassificationByForm = (item) => {
  //   if (item) {
  //     setCollection(item?.documentId)
  //     setIdFileDoc(collection[0])
  //   }
  // }

  const handleClassification = (docValidatedData) => {
    try {
      let localCount = 0

      const newDocListData = configData?.documents?.map((doc) => {
        if (doc.documentId === docValidatedData.documentId) {
          return docValidatedData
        } else {
          return doc
        }
      })

      const analyzerDataValidatedDocument = analyzerDataList.find(
        (data) => data?.retorno?.idDocBox === docValidatedData?.documentId
      )

      newDocListData.forEach((document, index) => {
        try {
          const analyzerData = analyzerDataList.find(
            (data) => data?.retorno?.idDocBox === document?.documentId
          )
          if (!document.validated && !document.part) {
            if (analyzerData) {
              const docValidated = docValidatedData

              if (docValidated?.classification?.titulo) {
                const tituloKey = docValidated.classification.titulo
                const tituloValue =
                  analyzerDataValidatedDocument?.retorno?.dados[
                    tituloKey
                  ]?.toLowerCase() || null
                const ocrContent =
                  analyzerData?.retorno?.metaDados?.ocrContent?.toLowerCase() ||
                  ''

                if (tituloValue && ocrContent) {
                  const tituloValueNormalized = tituloValue.toLowerCase().trim()
                  const ocrContentNormalized = ocrContent.toLowerCase().trim()

                  const removeSpecialCharacters = (str) =>
                    str.replace(/[^\w\s]/gi, '')

                  const tituloValueClean = removeSpecialCharacters(
                    tituloValueNormalized
                  )
                  const ocrContentClean =
                    removeSpecialCharacters(ocrContentNormalized)

                  const allWordsMatch =
                    ocrContentClean.includes(tituloValueClean)

                  if (allWordsMatch) {
                    const updatedDocument = {
                      ...document,
                      part: docValidatedData?.part || null,
                      numberTag: docValidatedData?.numberTag || null,
                    }

                    Object.assign(updatedDocument, { suggested: true })

                    // Incrementa a variável local
                    localCount += 1
                  }
                }
              }
            }
          }
        } catch (error) {
          console.error(`Erro no loop na iteração ${index}:`, error)
        }
      })

      // Usa localCount para verificar se deve exibir o toast
      if (localCount > 0) {
        const message =
          localCount === 1
            ? `Identificamos e classificamos 1 documento que parece estar relacionado à mesma parte!`
            : `Identificamos e classificamos ${localCount} documentos que parecem estar relacionados à mesma parte!`

        toast.info(message, { theme: 'light' })
        try {
          appInsights &&
            appInsights.trackEvent({
              name: 'Documentos associados com sucesso',
              properties: {
                docValidatedId: docValidatedData?.documentId,
                docValidatedPart: docValidatedData?.part,
                associatedDocs: localCount,
              },
            })
        } catch (error) {
          appInsights && appInsights.trackException({ error: new Error(error) })
        }
      }

      setDocumentListData(newDocListData)
      handleConfigData(newDocListData)
    } catch (error) {
      console.error('Erro em handleClassification:', error)
    }
  }

  const handleDataDocumentToLint = (data) => {
    setDataDocumentToLint((prev) => {
      const hasData = prev.findIndex(
        (item) => item.part === data.part && item.numberTag === data.numberTag
      )

      return hasData !== -1
        ? prev.map((item, index) => (index === hasData ? data : item))
        : [...prev, data]
    })
  }

  // const updatedDataDocumentToLint = (data) => {
  //   const hasInDocumentToLint = dataDocumentToLint.find(item => item.part === activeTag.part && item.numberTag === activeTag.numberTag)
  //   if(hasInDocumentToLint) {
  //     setDataDocumentToLint((prev) => ({

  //     }))
  //   }
  // }

  const updatedDataDocumentToLint = (name, value) => {
    // console.log({name, value})
    setDataDocumentToLint((prevData) => {
      return prevData.map((item) => {
        if (
          item.part === activeTag.part &&
          item.numberTag === activeTag.numberTag
        ) {
          return {
            ...item,
            data: {
              ...item.data,
              [name]: value,
            },
          }
        }
        return item
      })
    })
  }

  // const handleAllDataValidated = (data) => {
  //   console.log('dados que estao sendo salvos:', data)
  //   if (dataDocumentToLint) {
  //     console.log(dataDocumentToLint)
  //     const isMatchingData =
  //       dataDocumentToLint.part === data.part &&
  //       dataDocumentToLint.numberTag === data.numberTag

  //     console.log('updatedData:', isMatchingData ? dataDocumentToLint : null)
  //     setAllDataValidated((prevData) => {
  //       const hasTag = prevData.some(
  //         (item) => item.part === data.part && item.numberTag === data.numberTag
  //       )
  //       return hasTag
  //         ? prevData?.map((item) =>
  //             item.part === data.part && item.numberTag === data.numberTag
  //               ? {
  //                   ...item,
  //                   dataValidated: !item.dataValidated,
  //                   dataIa: isMatchingData.data,
  //                 }
  //               : item
  //           )
  //         : [...prevData, data]
  //     })
  //   }
  // }

  const handlePartFormUpdate = (tag) => {
    // console.log(`Tag para update:`, tag)
    // // console.log(`partFormListState:`, partFormListState)
    // // console.log(`partFormListState:`, document)
    // console.log('dataDocumentToLint', dataDocumentToLint)FF
    // console.log('isFormDataEmpty', isFormDataEmpty)
    if (isFormDataEmpty) {
      return toast.error(
        `Por favor, revise e preencha todos os campos antes de validar.`,
        {
          theme: 'light',
        }
      )
    }
    const hasDocumentToLintFull = ``

    const updatedForm = partFormListState?.map((item) => {
      if (item?.part === tag.part && item?.numberTag === tag.numberTag) {
        return {
          ...item,
          dataValidated: !item.dataValidated,
        }
      }
      return item
    })

    // console.log('updatedForm', updatedForm)

    const updatedActiveTag = updatedForm?.find(
      (item) => item.part === tag.part && item.numberTag === tag.numberTag
    )

    handleAllDataValidated(updatedActiveTag)
    // console.log('activetag antes', activeTag)
    // console.log('updatedActiveTag', updatedActiveTag)
    setActiveTag(updatedActiveTag)
    setPartFormListState(updatedForm)
    try {
      appInsights &&
        appInsights.trackEvent({
          name: 'Formulário validado com sucesso',
          properties: {
            updatedForm,
          },
        })
    } catch (error) {
      appInsights && appInsights.trackException({ error: new Error(error) })
    }
  }

  const handleAllDataValidated = (data) => {
    // console.log('dados que estão sendo salvos:', data)

    if (dataDocumentToLint) {
      // console.log(dataDocumentToLint)

      const isMatchingData =
        dataDocumentToLint.part === data.part &&
        dataDocumentToLint.numberTag === data.numberTag

      setAllDataValidated((prevData) => {
        const hasTag = prevData.some(
          (item) => item.part === data.part && item.numberTag === data.numberTag
        )

        return hasTag
          ? prevData.map((item) =>
              item.part === data.part && item.numberTag === data.numberTag
                ? {
                    ...item,
                    dataValidated: !item.dataValidated,
                    dataIa: isMatchingData
                      ? dataDocumentToLint.data
                      : item.dataIa,
                  }
                : item
            )
          : [
              ...prevData,
              {
                ...data,
                dataIa: isMatchingData ? dataDocumentToLint.data : null,
              },
            ]
      })
    }
  }

  // useEffect(() => {
  //   console.log('allDataValidated', allDataValidated)
  //   console.log('dataDocumentToLint', dataDocumentToLint)
  // }, [allDataValidated])

  const handleCopyToClipboard = (formData) => {
    console.log({ formData })
    if (!formData.data || Object.keys(formData.data).length === 0) {
      return toast.error(`Por favor, preencha os dados antes de continuar!`, {
        theme: 'light',
        progressStyle: { backgroundColor: '#a6151c' },
        style: {
          background: '#f4f4f4',
        },
      })
    }

    const formFields = resumeTags?.find((item) => item.tag === activeTag?.part)

    console.log('formFields', formFields)

    const formDataText = formFields?.itens
      ?.map((doc) => `${doc.label}: ${formData.data[doc.key] || ''}`)
      .join('\n')

    console.log('transferencia:', formDataText)

    navigator.clipboard
      .writeText(formDataText)
      .then(() =>
        toast.success(`Dados copiados para a área de transferência!`, {
          theme: 'light',
          progressStyle: { backgroundColor: '#9ca624' },
          style: {
            background: '#f4f4f4',
          },
        })
      )
      .catch((err) =>
        console.error('Erro ao copiar para a área de transferência: ', err)
      )
  }

  const handleCheckFormEmpty = (checkFormEmpty) => {
    setIsFormDataEmpty(checkFormEmpty)
  }

  useEffect(() => {
    // console.log('allDataValidated', allDataValidated)
    // console.log('quando muda o dataDocumentToLint', dataDocumentToLint)
  }, [dataDocumentToLint])

  const handleLoadingForm = (value) => {
    // console.log('ta batendo aqui?', value)
    setIsLoadingForm(value)
  }

  // useEffect(() => {
  //   if (configData?.documents && showForm) {
  //     setIsLoadingForm(true)
  //     const finalDocumentsArray = groupDocuments(
  //       configData?.documents,
  //       tagsWithColors
  //     )

  //     const groupedDocuments = finalDocumentsArray.reduce((acc, doc) => {
  //       const key = `${doc.part}-${doc.numberTag}`
  //       if (!acc[key]) {
  //         acc[key] = {
  //           id: Object.keys(acc)?.length + 1,
  //           part: doc.part,
  //           numberTag: doc.numberTag,
  //           documents: [],
  //           tagAdd: doc.tagAdd,
  //         }
  //       }

  //       doc.documentId?.forEach((id) => {
  //         const docConfigData = configData.documents?.find(
  //           (item) => item.documentId === id
  //         )
  //         const docAnalyzerData = analyzerDataList?.find(
  //           (item) => item?.retorno && item.retorno.idDocBox === id
  //         )

  //         if (docConfigData || docAnalyzerData) {
  //           acc[key].documents.push({
  //             documentId: id,
  //             documentData: {
  //               configData: docConfigData || {},
  //               analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
  //             },
  //           })
  //         }
  //       })

  //       return acc
  //     }, {})

  //     const otherDocuments = configData?.documents?.reduce((acc, doc) => {
  //       // console.log('chega a bater aqui?')
  //       //   console.log('entra aqui?')
  //       if (analyzerDataList) {
  //         const docAnalyzerData = analyzerDataList?.find(
  //           (item) => item?.retorno && item.retorno.idDocBox === doc.documentId
  //         )
  //         // console.log({ docAnalyzerData })
  //         acc.push({
  //           documentId: doc.documentId,
  //           documentData: {
  //             configData: doc || {},
  //             analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
  //           },
  //         })
  //       }

  //       return acc
  //     }, [])

  //     if (otherDocuments?.length > 0) {
  //       groupedDocuments['Outros'] = {
  //         id: Object.keys(groupedDocuments)?.length + 1,
  //         part: 'Outros',
  //         numberTag: null,
  //         documents: otherDocuments,
  //       }
  //     }

  //     const finalArray = Object.values(groupedDocuments)

  //     if (finalArray.length > 0) {
  //       const newCombinededDocuments = finalArray.map((doc) => {
  //         const dadosList =
  //           doc.documents?.flatMap((item) => {
  //             const dados = item?.documentData?.analyzerData?.dados || {}
  //             const label =
  //               item?.documentData?.configData?.classification?.label

  //             return Object.entries(dados).map(([key, value]) => ({
  //               key,
  //               label: label ? label : 'Não classificado',
  //               value: value ? value : 'Não cadastrado',
  //             }))
  //           }) || []

  //         const dadosSeparados = dadosList
  //           .filter(({ key }) => !/hidden/i.test(key))
  //           .map(({ key, value, label }) => ({
  //             key,
  //             label,
  //             value,
  //           }))

  //         return {
  //           part: doc?.part,
  //           numberTag: doc?.numberTag,
  //           dados: dadosSeparados,
  //         }
  //       })

  //       console.log(
  //         'quem tem dentro de newCombinededDocuments',
  //         newCombinededDocuments
  //       )

  //       if (newCombinededDocuments.length > 0) {
  //         setCombinededDocuments(newCombinededDocuments)
  //         if (newCombinededDocuments.length > 0) {
  //           setTimeout(() => {
  //             setIsLoadingForm(false)
  //           }, 3000)
  //         }
  //         console.log({ finalArray })
  //       }
  //     }

  //     // const processDocuments = (configData, tagsWithColors) => {
  //     //   if (configData?.documents) {
  //     //     // Agrupar documentos
  //     //     const groupedDocuments = configData.documents.reduce((acc, doc) => {
  //     //       const key = `${doc.part}-${doc.numberTag}`

  //     //       if (!acc[key]) {
  //     //         acc[key] = {
  //     //           id: Object.keys(acc).length + 1,
  //     //           part: doc.part,
  //     //           numberTag: doc.numberTag,
  //     //           documents: [],
  //     //           tagAdd: doc.tagAdd,
  //     //         }
  //     //       }

  //     //       doc.documentId?.forEach((id) => {
  //     //         const docConfigData = configData.documents.find(
  //     //           (item) => item.documentId === id
  //     //         )
  //     //         const docAnalyzerData = configData.documents.find(
  //     //           (item) => item?.retorno && item.retorno.idDocBox === id
  //     //         )

  //     //         if (docConfigData || docAnalyzerData) {
  //     //           acc[key].documents.push({
  //     //             documentId: id,
  //     //             documentData: {
  //     //               configData: docConfigData || {},
  //     //               analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
  //     //             },
  //     //           })
  //     //         }
  //     //       })

  //     //       return acc
  //     //     }, {})

  //     //     // Obter documentos "Outros"
  //     //     const otherDocuments = configData.documents.reduce((acc, doc) => {
  //     //       if (!doc.part && !doc.numberTag) {
  //     //         const docAnalyzerData = configData.documents.find(
  //     //           (item) =>
  //     //             item?.retorno && item.retorno.idDocBox === doc.documentId
  //     //         )
  //     //         acc.push({
  //     //           documentId: doc.documentId,
  //     //           documentData: {
  //     //             configData: doc || {},
  //     //             analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
  //     //           },
  //     //         })
  //     //       }
  //     //       return acc
  //     //     }, [])

  //     //     const finalArray = Object.values(groupedDocuments)

  //     //     if (otherDocuments.length > 0) {
  //     //       finalArray.push({
  //     //         id: Object.keys(groupedDocuments).length + 1,
  //     //         part: 'Outros',
  //     //         numberTag: null,
  //     //         documents: otherDocuments,
  //     //       })
  //     //     }

  //     //     // Combinar dados dos documentos
  //     //     const newCombinedDocuments = finalArray.map((doc) => {
  //     //       const dadosList =
  //     //         doc.documents?.flatMap((item) => {
  //     //           const dados = item?.documentData?.analyzerData?.dados || {}
  //     //           const label =
  //     //             item?.documentData?.configData?.classification?.label ||
  //     //             'Não classificado'

  //     //           return Object.entries(dados).map(([key, value]) => ({
  //     //             key,
  //     //             label,
  //     //             value: value || 'Não cadastrado',
  //     //           }))
  //     //         }) || []

  //     //       const dadosSeparados = dadosList
  //     //         .filter(({ key }) => !/hidden/i.test(key))
  //     //         .map(({ key, value, label }) => ({ key, label, value }))

  //     //       return {
  //     //         part: doc.part,
  //     //         numberTag: doc.numberTag,
  //     //         dados: dadosSeparados,
  //     //       }
  //     //     })

  //     //     // Atualiza o estado apenas se necessário
          
  //     //     setCombinededDocuments(newCombinedDocuments)
  //     //     handleLoadingForm(false)
  //     //   }
  //     // }
  //   }

  //   // Chame esta função passando configData e tagsWithColors
  //   // Exemplo: processDocuments(configData, tagsWithColors);
  // }, [configData, tagsWithColors]) // Adicione aqui as dependências necessárias

  /***** IA */

  const handleFormDataToAi = async () => {
    if (activeTag.dataValidated) return
    if (isLoadingForm) return
    setIsLoadingForm(true)
    const PF = 'fisica'
    const PJ = 'juridica'

    const listFieldPJ =
      "'razaoSocial' nome da empresa, 'CNPJ' o CNPJ da empresa formatado como tal, 'endereco' com a rua, o numero e o complemento da empresa, 'bairro' bairro da empresa, 'cidade' cidade da empresa, 'estado' estado da empresa e 'CEP' cep da empresa formatado como tal"
    const listFieldPF =
      "'nome' nome da pessoa, 'CPF' o CPF da pessoa formatado como tal, 'endereco' com a rua, o número e o complemento da pessoa ou endereco do cliente, 'bairro' esta contido no endereco cliente, 'cidade' cidade da empresa, 'estado' estado da empresa e 'CEP' cep da empresa ou do cliente formatado como tal, 'numeroIdentidade' número do RG da pessoa, 'orgaoExpedidor' órgão expedidor ou orgão de expedição ou orgao Emissor do RG, 'dataExpedicao' data de expedição ou data de emissão do RG, 'nacionalidade' nacionalidade da pessoa e 'estadoCivil' estado civil da pessoa, 'nacionalidade' nacionalidade da pessoa, 'profissao' profissão da pessoa e 'email' endereço de e-mail da pessoa"

    const hasDataIa = combinededDocuments.filter(
      (doc) =>
        doc.part === activeTag.part && doc.numberTag === activeTag.numberTag
    )

    if (hasDataIa.length > 0) {
      try {
        const results = await Promise.allSettled(
          hasDataIa.map((doc) => {
            const payload = {
              tipoPessoa: doc.part === 'Empresa' ? PJ : PF,
              listaCampos: doc.part === 'Empresa' ? listFieldPJ : listFieldPF,
              dadosJson: doc.dados.map((d) => `${d.key}: ${d.value}`).join(','),
              retornarGPTPrompt: false,
            }

            return fetchWithAuth(
              `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/unifyData`,
              {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
              }
            ).then((response) => response.json())
          })
        )

        const combinedResult = results
          .filter(
            (result) =>
              result.status === 'fulfilled' && result.value.retorno?.dados
          )
          .reduce(
            (acc, result) => ({ ...acc, ...result.value.retorno.dados }),
            {}
          )

        Object.entries(combinedResult).forEach(([name, value]) => {
          updatedDataDocumentToLint(name, value)
        })

        console.log('Resultados das requisições?????', results)
        console.log('Dados combinados:', combinedResult)
      } catch (error) {
        console.error('Erro na classificação dos documentos:', error)
        toast.error('Erro na classificação dos documentos', { theme: 'light' })
      } finally {
        console.log('Processamento finalizado')
        setIsLoadingForm(false)
      }
    }

    try {
      appInsights &&
        appInsights.trackEvent({
          name: 'Formulário preenchido com IA',
          properties: {
            documentId: idFileDoc,
          },
        })
    } catch (error) {
      appInsights && appInsights.trackException({ error: new Error(error) })
    }
  }

  useEffect(() => {
    // console.log('aqui é combined', combinededDocuments)
  }, [combinededDocuments])
  return (
    <Provider
      value={{
        startingLoading,
        errorDocBox,
        isFormDataEmpty,
        isLoadingForm,
        handleLoadingForm,
        combinededDocuments,
        handleCheckFormEmpty,
        updatedDataDocumentToLint,
        handleCollection,
        handleCopyToClipboard,
        tagRequiredFile,
        labelTag,
        showLabelTag,
        canDownloaded,
        canPrinted,
        validatedDocumentsModalOpen,
        showLabelText,
        handleOpenModalValidatedDocuments,
        handleFormDataToAi,
        showClassification,
        validationDocument,
        showContractPart,
        handleClassification,
        handleDocumentsPartArray,
        handleDataDocumentToLint,
        dataDocumentToLint,
        previewBoxVisibility,
        documentsPartArray,
        handleAddFilter,
        boxToken,
        progressBar,
        tagsWithColors,
        setTagsWithColors,
        colorsByDocumentId,
        setDocumentColor,
        getDocumentColor,
        handleActiveTag,
        activeTag,
        previewSize,
        handleConfigData,
        handleSelectedDocument,
        configData,
        configId,
        docSelected,
        handleAnalyzerData,
        documentListData,
        contractPartsDoc,
        classificationListDocs,
        filterSelectedColor,
        handleClearFilter,
        handleAllDataValidated,
        allDataValidated,
        handleFilter,
        handleErrorDocBox,
        filteredPartsContract,
        handleContractParts,
        setContractPartsSelected,
        contractPartsSelected,
        typeDocuments,
        handleTagSelected,
        partSelected,
        handleTypeDocumentsOption,
        openModalSendDocs,
        handleOpenModalSend,
        setAllDocsValidated,
        handleAllDocsValidatesValue,
        showDeleteButton,
        handleClassificationDocs,
        fileId,
        submitDocList,
        setSubmitDocList,
        updatedFilterSelected,
        collection,
        validatedDocument,
        showForm,
        setValidatedDocument,
        handleQttValidation,
        handlePartForm,
        handlePartFormUpdate,
        partFormListState,
        handleValidateDocument,
        setTotalValidateDocs,
        setIdFileDoc,
        totalValidateDocs,
        allDocsValidated,
        idFileDoc,
        analyzerDataList,
        confirmEditValuesDocLInt,
        setConfirmEditValuesDocLInt,
        setValidatedDocumentsModalOpen,
        setStartingLoading,
        sendNewDocument,
        checkToken,
        handleUpdateActiveTag,
        openModalAllLists,
        showListDocuments,
        handleOpenModalAllLists,
        showError,
        resumeTags,
        openDocumentPreview,
        showResumeDocToContract,
        handlePreviewDocsReview,
        setAnalyzerDataList,
        setDocumentListData,
      }}
    >
      {props.children}
    </Provider>
  )
}
